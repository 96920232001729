import React , {useState , useEffect}  from 'react';
import './isetSlider.css'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {NewSlider} from "../../mockData";
import {NewSliderEng} from "../../mockDataEnd";

import {log} from "three";


import {
    VuejsIcon,
    ReactIcon,
    JavaScriptIcon,
    HtmlIcon,
    CssIcon,
    AzureIcon,
    PhpIcon,
    RedisIcon,
    BootstrapIcon,
    KubernetesIcon,
    LinuxIcon,
    MysqlLogo,
    LaravelIcon,
    MongodbIcon, AngularIcon, AwsIcon, WPressIcon,

} from '../../icons';
import {useLocation} from "react-router-dom";




const IsetSlider = () => {
    const [windowWidth, setWindowWidth] = useState(1440)
    const [windowHeight, setWindowHeight] = useState(0)


    const [spaceBetween , setSpaceBetween] = useState(45)
    const [slideWidth , setSlideWidth] = useState(310)
    const [sliderProcent , setSliderProcent] = useState('px')
    const [sliderContent , setSliderContent] = useState(NewSlider)
    const [count , setCount] = useState(0)
    const [modalInfo , setModalInfo] = useState({})
    const [modalActive , setModalActive] = useState(false)
    const [translateWidth , setTranslateWidth] = useState(60)
    const [marginWidth , setMarginWidth] = useState(174)
    const [countAdaptation , setCountAdaptation] = useState(1)
    const [title ,  setTitle] = useState('проекти')
    const [modaltext , setModalText] = useState('Технології проекту :')

    const loc = useLocation().pathname;

    function countNext(){
        setCount(count + 1)
        if (count == sliderContent.length - 1){
            setCount(0)
        }
    }
    function countPrev(){
        setCount(count - 1)
        if (count == 0){
            setCount(sliderContent.length -1)
        }
    }

    const modalContent = (count = 0) =>{
        setModalInfo(sliderContent[count])
        setModalActive(true)
    }

    const onResize = (windowWidth, windowHeight) => {
        setWindowWidth(windowWidth);
        setWindowHeight(windowHeight)
    }

    useEffect(()=>{

        const  header = document.querySelector('header')
        const  footerHome = document.querySelector('.footer')
        if (loc == '/projects'){
            header.style.display = 'flex' ;
            footerHome.style.display = 'flex';
        }

        if (localStorage.getItem('language') == 'en'){
            setSliderContent(NewSliderEng)
            setTitle('Projects')
            setModalText('Project technologies :')
        }else {
            setSliderContent(NewSlider)
            setTitle('Проекти')
            setModalText('Технології проекту :')
        }

        document.addEventListener('resize', onResize(window.innerWidth, window.innerHeight));
        if (windowWidth < 500){
            setTranslateWidth(0)
            setMarginWidth(0)
            setCountAdaptation(0)
            setSlideWidth(100)
            setSliderProcent('vw')
            setSpaceBetween(0)
        }else if (windowHeight < 860 && windowWidth > 1200){
            setTranslateWidth(40)
        }else {
            setTranslateWidth(70)
            setSpaceBetween(45)
            setSliderProcent('px')
            setCountAdaptation(1)
            setMarginWidth(174)
            setSlideWidth(310)
        }
    })

    const modal = (
      <div className={ modalActive == false ? 'modal-project' : 'modal-project active'}>
          <div className={modalActive == false ?"modal-blocks": 'modal-blocks active'}>
              <h4 className={'name-modal-project'}>{modalInfo.name}</h4>
              <div className="modal-block">
                  <div>
                      <h1 className={modalActive == false? '' : 'active'}>{modalInfo.title}</h1>
                      <p  className={modalActive == false? '' : 'active'}>{modalInfo.subtitle}</p>
                      <a target="blank" href={modalInfo.href} className={modalActive == false? '' : 'active'} >{modalInfo.href}</a>
                  </div>
                  <div>
                      <div style={{backgroundImage: 'url(' + modalInfo.mainImg + ')'  }} className={modalActive == false? "images-project" : 'images-project animate'}>

                      </div>
                  </div>
              </div>
              <div className="modal-block">
                  <img className={modalActive == false? '' : 'active'} src={modalInfo.secondImg} alt=""/>
                  <div className={"texnology-wrap"}>
                      <span className={modalActive == false? '' : 'active'}>{modaltext}</span>
                      <div className={ modalActive == false? "skills-modal" : 'skills-modal active'}>
                          <div className="skill">
                              {modalInfo.skills}
                          </div>
                      </div>
                  </div>
                  <div className={"modal-back"} onClick={()=> setModalActive(false)}>
                      <img  className={modalActive == false? '' : 'active'} src="./img/icons/sliderNext.svg" alt=""/>
                  </div>
              </div>
          </div>
      </div>
    );


    return (
        <div className={'views-container'}>
                <div className="wrapper wrapper-project page-animation">
                    <div className="wrapper-title-mobile">
                        <h1>Isetsoft</h1>
                        <h2>{title}</h2>
                    </div>
                    <div className={modalActive == false? 'slider-container' : 'slider-container active'}>
                        <div className="wrapper-title">
                            <h1>Isetsoft</h1>
                            <h2>{title}</h2>
                        </div>
                        <div className={modalActive == false? "slider-wrapper" : 'slider-wrapper slider-wrapper-active'} style={ count == 0 ? {marginLeft: '-'+(((spaceBetween + slideWidth ) * 0)) + sliderProcent , marginTop: translateWidth  * count + 'px'   } : {marginLeft: '-'+(((spaceBetween + slideWidth ) * (count - countAdaptation)))- marginWidth - marginWidth/2 + sliderProcent , marginTop: translateWidth * count + 'px'    }}>
                            {sliderContent.map( (slider , i) =>
                                <div onClick={i == count ? ()=> modalContent(i) : ()=> setCount(i)} key={i}
                                     className={count == i ? "slider-slides slider-active" : 'slider-slides'}
                                     style={{transform : 'translateY('+ ((60 * (sliderContent.length - 5) ) - (i * translateWidth )) + 'px)' ,
                                         backgroundImage: 'url('+ slider.mainImg + ')',
                                         minWidth: slideWidth + sliderProcent}}>

                                    <p>{slider.name}</p>
                                </div>
                            )}
                        </div>
                        {modal}
                    </div>
                    <div className={modalActive == false? "pagination-containers pagination-containers-project" : "pagination-container pagination-containers-project active"}>
                        <div className="pagination-container">
                            <div className="pagination-bullet">
                                <div className="pagination-wrapper" style={count > 1 ? {marginLeft: '-' + (count - 1) * (18) + 'px'} :{marginLeft: '-' + (count) * (0) + 'px'}}>
                                    {sliderContent.map((slide , i )=>
                                        <div key={i} id={i} className={ count == i ? ' bullet active-bullet': 'bullet'  }>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="swiper-pagination-academy">
                                <p>0{count + 1}</p>
                                <span>
                            \0{sliderContent.length}
                        </span>
                            </div>
                        </div>
                        <div className="pagination-container">
                            <div onClick={countNext} className="swiper-next"><img src="./img/icons/sliderNext.svg" alt=""/></div>
                            <div onClick={countPrev} className="swiper-prev"> <img src="./img/icons/sliderPrev.svg" className="slider-prev" alt=""/></div>
                        </div>
                    </div>

                    <div className={modalActive == false?"pagination-mobile" :"pagination-mobile active"}>
                        <div className="pagination-container">
                            <div onClick={countPrev} className="swiper-prev"> <img src="./img/icons/sliderPrev.svg" className="slider-prev" alt=""/></div>
                            <div className="pagination-bullet">
                                <div className="pagination-wrapper" style={count > 1 ? {marginLeft: '-' + (count - 1) * (18) + 'px'} :{marginLeft: '-' + (count) * (0) + 'px'}}>
                                    {sliderContent.map((slide , i )=>
                                        <div key={i} id={i} className={ count == i ? ' bullet active-bullet': 'bullet'  }>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div onClick={countNext} className="swiper-next"><img src="./img/icons/sliderNext.svg" alt=""/></div>
                        </div>
                        <div className="pagination-container">
                            <div className="swiper-pagination-academy">
                                <p>0{count + 1}</p>
                                <span>
                                 \0{sliderContent.length}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default IsetSlider;
import React, {useEffect, useRef, useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube } from "swiper";
import './Skills.css';
import 'swiper/css';
import "swiper/css/effect-cube";
import Cube from "./Cube";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import {
    VuejsIcon,
    ReactIcon,
    JavaScriptIcon,
    HtmlIcon,
    CssIcon,
    AzureIcon,
    PhpIcon,
    RedisIcon,
    BootstrapIcon,
    KubernetesIcon,
    LinuxIcon,
    MysqlLogo,
    LaravelIcon,
    MongodbIcon, AngularIcon, AwsIcon, WPressIcon,

} from '../../icons';
import {useLocation} from "react-router-dom";



function SkillsPage() {
    const loc = useLocation().pathname;
    const [title , setTitle ] = useState('Технології ')

    useEffect(()=>{
        const  header = document.querySelector('header')
        const  footerHome = document.querySelector('.footer')
        if (loc == '/skills'){
            header.style.display = 'flex' ;
            footerHome.style.display = 'flex';
        }


        if (localStorage.getItem('language') == 'en'){
            setTitle('Technologies')
        }else {
            setTitle('технології')
        }
    })


  const content = (
      <div className={"skill-page"}>
          <div className="skills-title">
              <h1>Isetsoft</h1>
              <h2>{title}</h2>
          </div>
          <div className="cube-wrapper" >

              <Cube classCube={'Cube1'} secondClass={'blackBg'} firstClass={'whineBg'} deleySlider={2000} firstIcons={VuejsIcon} secondIcons={ReactIcon}></Cube>
              <Cube classCube={'Cube2'} secondClass={'blackBg'} firstClass={'whineBg'} deleySlider={4000} firstIcons={JavaScriptIcon} secondIcons={HtmlIcon}></Cube>
              <Cube classCube={'Cube3'} secondClass={'whineBg'} firstClass={'blackBg'} deleySlider={1000} firstIcons={CssIcon} secondIcons={AzureIcon}></Cube>
              <Cube classCube={'Cube4'} secondClass={'blackBg'} firstClass={'whineBg'} deleySlider={2400} firstIcons={PhpIcon} secondIcons={RedisIcon}></Cube>
              <Cube classCube={'Cube5'} secondClass={'whineBg'} firstClass={'blackBg'} deleySlider={3000} firstIcons={BootstrapIcon} secondIcons={KubernetesIcon}></Cube>
              <Cube classCube={'Cube6'} secondClass={'blackBg'} firstClass={'whineBg'} deleySlider={1800} firstIcons={LinuxIcon} secondIcons={MysqlLogo}></Cube>
              <Cube classCube={'Cube7'} secondClass={'blackBg'} firstClass={'whineBg'} deleySlider={3400} firstIcons={MongodbIcon} secondIcons={LaravelIcon}></Cube>
              <Cube classCube={'Cube8'} secondClass={'blackBg'} firstClass={'whineBg'} deleySlider={3200} firstIcons={WPressIcon} secondIcons={AngularIcon} ></Cube>
              <div className={'decorative-first'}></div>
              <div className={'decorative-second'}></div>
          </div>

      </div>
  );



  return (
      <div className={'views-container '}>
              <div className="skill-page page-animation">
                  <div className="wrapper">
                      {content}
                  </div>
              </div>
      </div>

  );
}

export default SkillsPage;


import React, {useState,useEffect} from 'react';
import {Link} from "react-router-dom";
import './AboutPage.css'
import {AboutInfo} from '../../mockData'
import {AboutInfoEng} from '../../mockDataEnd'

const AboutBlock = () => {
    const [infoPage ,  setInfoPage] = useState([])
    const [active , setActive] = useState(0)
    const [buttonEng , setButtonEng] = useState('Оформити')
    useEffect(()=>{
        if (localStorage.getItem('language') == 'en'){
            setInfoPage(AboutInfoEng)
            setButtonEng('to issue')
        }else {
            setInfoPage(AboutInfo)
        }
    })

    return (
        <div>
            <div className="about-blocks">
                {infoPage.map((el , i) =>
                    <div key={i} onClick={()=> setActive(i)} className={active == i ? "about-block about-block-active" : 'about-block' }>
                        <div className={'about-block-title'}>
                            <span>0{el.id}.</span>
                            <h4 className={"site-name"}>{el.title}</h4>
                            <p className={'site-title'}>{el.subtitle}</p>
                            <p className={'site-info'}>{el.info}</p>
                        </div>

                        <div className={'price-wrapper'}>
                            <p className={'site-price'}>{el.price}</p>
                            <Link className="button-site" to="/contacts"  onClick={() => localStorage.setItem('type' , el.contact)}>
                                {buttonEng}
                            </Link>
                        </div>

                    </div>
                )}
            </div>
        </div>
    );
};

export default AboutBlock;
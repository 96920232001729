import React, {useEffect, useState} from 'react';
import AboutBlock from "./AboutBlock";
import {Link, useLocation} from "react-router-dom";
import './AboutPage.css'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";


const AboutPage = () => {

    const [active , setActive] = useState(0)
    const [title , setTitle] = useState('послуги')
    const [subtitle , setSubTitle] = useState('послуги')
    const loc = useLocation().pathname;

    useEffect(()=>{
        const  header = document.querySelector('header')
        const  footerHome = document.querySelector('.footer')
        if (loc == '/about'){
            header.style.display = 'flex' ;
            footerHome.style.display = 'flex';
        }


        if (localStorage.getItem('language') == 'en'){
            setTitle('Services')
            setSubTitle("We focus on the idea of creating a functional and unsurpassed product. It’s pleasant to see how our services meet the needs of our customer’s users.")
        }else {
            setTitle('послуги')
            setSubTitle('Ми фокусуємося на втілення ідеї в фукціональний і неперевершенний продукт. Задоволення бачити, як наші сервіси задовільняють потреби користувачів наших клієнтів.')
        }
    })
  const aboutPage = (
      <div className={"about-page-container"}>
          <div className="about-title">
              <div className="about-title-block">
                  <h1>isetsoft</h1>
                  <h2>{title}</h2>
              </div>
              <div className="about-title-block">
                <p>{subtitle}</p>
              </div>
          </div>
          <AboutBlock></AboutBlock>

      </div>
  );

  return (
      <div  className={'views-container'}>
              <div className="about-page--wrapper page-animation">
                  <div className="wrapper wrapper-mobile">
                      {aboutPage}
                  </div>
              </div>
      </div>

  )
}

export default AboutPage;

{/*<div className="servises-block">*/}
{/*  {AboutInfo.map((item , i)=>*/}
{/*    <div key={i} className="servises-blocks">*/}
{/*      <div>*/}
{/*        <h3>{item.title}</h3>*/}
{/*        <p className="price">*/}
{/*          {item.price}*/}
{/*        </p>*/}
{/*        <p className="description">*/}
{/*          {item.subtitle}*/}
{/*        </p>*/}
{/*      </div>*/}

{/*      <Link className="button" to="/contacts"  onClick={() => localStorage.setItem('type' , item.title)}>*/}
{/*        Оформити*/}
{/*      </Link>*/}
{/*    </div>*/}
{/*  )}*/}

{/*</div>*/}
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import {  FacebookIcon,  InstagramIcon, TelegramIcon, WhatsappIcon } from '../../icons';

function Footer() {

  return (
    <div className="footer">
      <div className="wrapper">
        <div className="footer-row">
          <Link className="footer-logo" to="/">{'ISETSOFT'}</Link>
          <div className="network">
            <a href="https://www.facebook.com/Isetsoft-101178219433926" target="blank">{FacebookIcon}</a>
            <a href="viber://pa?chatURI=isetsoft-agency" target="blank">{WhatsappIcon}</a>
            <a href="https://www.instagram.com/isetsoft/" target="blank">{InstagramIcon}</a>
            <a  href="https://t.me/iset_order_bot" target="blank" className="telegramim_button telegramim_shadow" title="">{TelegramIcon}</a>
          </div>
          <p>
            © 2022 ISETSOFT,Privacy Policy
          </p>
        </div>

      </div>
    </div>
  );
}

export default Footer;
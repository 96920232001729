


export const sliderSoft = [
    {
        id: 1,
        title:'курс:',
        subtitle:'Frontend developer',
        img:'academy2.png',
        link:'https://www.isetsoft-academy.com.ua/course/frontend',
        images:[
            'academy2.png',
            'academy4.png',
            'academy5.png'
        ],
    },
    {
        id: 2,
        title:'курс:',
        subtitle:'UX/UI Design',
        img:'academy1.png',
        link:'https://www.isetsoft-academy.com.ua/course/design',
        images:[
            'academy1.png',
            'academy8.png',
            'academy9.png',
            'academy10.png'
        ],
    },
    {
        id: 3,
        title:'курс:',
        subtitle:'Backend developer',
        img:'academy3.png',
        link:'https://www.isetsoft-academy.com.ua/course/backend',
        images:[
            'academy3.png',
            'academy6.png',
            'academy7.png',
        ],
    }

];

export const newsSoft = [
    {
        id: 5,
        title:'березень',
        year:2023,
        news:[
            {
                id: 0 ,date:'29.03.2023', dateSlider:'29 березеня' , titleNews: '«Онлайн зустріч»' , subtitleNews : 'Ви дізнаєтесь особливості та переваги професії, отримаєте чітке розуміння що ж таке IT сфера. Лектори розкажуть усю середину IT так, як не розкаже ніхто інший.', link:'(Деталі за номером 0681628957).' ,  img: 'news29.png'
            },
            {
                id: 1 ,date:'31.03.2023', dateSlider:'31 березеня' , titleNews: '«Вечір карʼєри»' , subtitleNews : 'Ми розкажемо, чому варто саме зараз розпочинати свій шлях в ІТ індустрію та чому саме з ISETSOFT Academy. Проведемо в ІТ світ та пригостимо смачною кавою.', link:'Обовʼязковий попередній запис 0681628957.', img: 'news31.png'
            },
        ]
    },
    {
        id: 6,
        title:'квітень',
        year:2023,
        news:[
            {
                id: 2 ,date:'01.04.2023', dateSlider:'1 квітня' , link:'', titleNews: 'Старт курсу «UX/UI Design»' , subtitleNews : 'Лекторка Наталя Ряшко дасть чітке розуміння поняттю UX/UI дизайн та пояснить, чому творчість займає лише 10% від усієї роботи.\n' + 'У програмі: Wireframing, User interviews, CJM, User persona, User-flow, MVP.', img: 'newsUx.png'
            },
            {
                id: 0 ,date:'10.04.2023', dateSlider:'10 квітня' ,link:'', titleNews: 'Старт курсу «Backend developer»' , subtitleNews : 'Лектор Андрій Зотов в деталях пояснить усі принципи роботи зі сервером.\n' + 'У програмі: Html, CSS, вивчення PHP, сервер MySQL.', img: 'newsback.png'
            },
            {
                id: 1 ,date:'10.04.2023', dateSlider:'31 квітня' ,link:'', titleNews: 'Старт курсу «Frontend developer»' , subtitleNews : 'Лектор Дмитро Дячук навчить з 0 розуміти та писати на HTML, CSS та JavaScript.\n' + 'У програмі: ClassList та делегування подій, Json формат, Ajax, Fetch...', img: 'newsFront.png'
            },
        ]
    },

    {
        id: 3,
        title:'листопад',
        year:2022,
        news:[
            {
                id: 0 ,date:'15.11.2022', dateSlider:'15 листопада' ,link:'', titleNews: 'Вебінар «PHP фреймворк Laravel та його можливості»' , subtitleNews : 'Ми розглянемо, чому Laravel настільки популярний серед розробників та як легко і швидко можна створити веб-проект, використовуючи базові можливості Laravel.', img: 'news3.png'
            },
        ]
    },
    {
        id: 2,
        title:'жовтень',
        year:2022,
        news:[
            {
                id: 0 ,date:'25.10.2022', dateSlider:'25 жовтеня' ,link:'', titleNews: 'Вебінар «IT з нуля»' , subtitleNews : 'Розглянемо напрями ІТ, обговоримо перспективи, розберемо поріг входження, а також, що для цього потрібно.', img: 'news4.png'
            },
        ]
    },
    {
        id: 1,
        title:'вересень',
        year:2022,
        news:[
            {
                id: 0 , date:'10.09.2022',  dateSlider:'10 жовтеня' ,link:'', titleNews: 'Вебінар «Різниця між Junior та Trainee»' , subtitleNews : 'Розглянемо ідею хто такий Junior, хто такий Trainee. Чому в одних ІТ - компаніях ти Middle, а в інших Junior. Процес переходу від позиції до позиції, розуміння рівня своїх скілів.' , img: 'news1.png'
            },
            {
                id: 1 ,date:'17.09.2022', dateSlider:'17 жовтеня' ,link:'', titleNews: 'Вебінар «Аутсорс, фріланс»' , subtitleNews : 'Розглянемо специфіку роботи для  початківців в ІТ. Де і як обирати перші замовлення, робота з клієнтом, розуміння дедлайну.' ,  img: 'news2.png'
            },
        ]

    },

];





export const aboutContent = [
    {
        title: "Is Font Awesome open source?",
        text: "Font Awesome has been and will always continue to be an open source project. The current version of Font Awesome has a free version that includes all of the redesigned core icons plus updated frameworks. In addition to that, we've added a Free plan you can sign up for which gives you access to our kits feature, which makes using Font Awesome even easier and faster on the web. We've built more features and adding more icons on top of that with Font Awesome Pro."
    },
    {
        title: "What formats is Font Awesome available in?",
        text: "Font Awesome Free and Font Awesome Pro are available to you in a couple of different formats - as an icon font as well as SVGs. You may download copies of each for Font Awesome Free and if you hold a license for Pro, the same goes for Pro-only icons. Both formats also come with frameworks to make it easier to use each on the web. We've created documentation on how to use them both offline and online."
    },
    {
        title: "Do you take requests for icons?",

        text: "New icons mostly start as requests by the Font Awesome community on GitHub. Want to request a new icon? Here are some things to keep in mind: Please be nice. Font Awesome is a happy place. Please search to see if your icon request already exists. If a request is found, please add a reaction to that one. If there isn't an existing request, feel free to make one!"
    }
];

export const AboutInfo = [
    {
        id:1,
        title: 'Landing page',
        contact:'Landing page' ,
        price:'від 500 $',
        subtitle: 'Landing page або просто Лендінг - це особливий тип односторінкового сайту.',
        info: 'Його завдання - це знайомство з вашою компанією та її послугами.  Функція Лендінгу - спонукати відвідувача виконати певну «цільову» дію: підписка на розсилку, запит на прайс або каталог, реєстрація на сайті, покупка.'
    },
    {
        id:2,
        title: 'Інтернет магазин' ,
        contact:'Інтернет магазин' ,
        price:'від 1 000 $',
        subtitle: 'Інтернет магазин або e-commerce — це купівля та продаж товарів чи послуг онлайн.',
        info: 'Ви власник  інстаграм магазину та втомилися приймати стандартні замовлення через Direct? \n' +
            'Чи ви молодий бренд косметики і хочете, щоб ваші клієнти замовляли товари в два кліки онлайн? Інтернет магазин – ідеальний варіант та зручний інструмент для оптимізації бізнесу!\n'

    },
    {
        id:3,
        title: 'Корпоративні сайти' ,
        contact:'Корпоративний / Бізнес сайт' ,
        price:'від 600 $',
        subtitle: 'Корпоративний сайт – інтернет-майданчик для бізнесу, що несе корисну інформацію про компанію, її товари та послуги, новини та акції.',
        info:'Покращує імідж організації, підвищує продаж та спрощує комунікацію з клієнтами або партнерами, а також дозволяє управляти контентом, робити публікації новин/статей, отримувати зворотній зв’язок від користувачів, розміщувати каталог послуг/товарів, розміщувати  інформацію про представництва, філії, офіси, вакансії  і тп.'
    },
    {
        id:4,
        title: 'Чат – Боти' ,
        contact:'Чат – Боти' ,
        price:'від 200 $',
        subtitle: 'Чат-боти - зручні інструменти автоматизації на базі   штучного інтелекту, що замінюють менеджера.',
        info:'- Створення ботів: торгових, фінансових, для месенджерів \n' +
            '   та розсилки, ботів для бірж та трейдерів\n' +
            '- Збір бази даних\n' +
            '- Інтеграція платіжних методів\n' +
            '- Автореєстратори та автопостинги\n' +
            '- Розробка парсерів, автоматичний збір даних в інтернеті'
    },
    {
        id:5,
        title: 'Редизайн сайту' ,
        contact:'Редизайн сайту' ,
        price:'від 200 $',
        subtitle: 'Редизайн сайту - це не просто візуальне оформлення.',
        info:'Редизайн передбачає змінення структури сайта, покращення функціональності та оптимізації веб-ресурсу під потреби цільової аудиторії. \n' +
            'Необхідність редизайну виникає якщо сайт більше не виконує свої завдання, тоді проводиться аналіз сайта та оновлення відповідно до нових потреб ринку.',
    },
    {
        id:6,
        title: 'Бізнес сайт' ,
        contact:'Корпоративний / Бізнес сайт' ,
        price:'від 700 $',
        subtitle: 'Бізнес сайт - це багатосторінковий сайт, який підходить малому та середньому бізнесу.',
        info:'Його завдання полягає у формуванні іміджу компанії в онлайн середовищі, надання інформації про послуги та можливості компанії, залучення нових клієнтів та партнерів.'
    },

];

export const NewSlider = [
    {
        id:9,
        name: 'L-fut',
        mainImg : 'img/project/lfut/lfut2.png',
        secondImg : 'img/project/lfut/lfut1.png' ,
        year: '2022',
        skills: 'Laravel , JS , Css',
        title: "L-fut",
        subtitle: "Платформа де можна купити монети FIFA 23 для PlayStation 4, PlayStation 5, Xbox One, Xbox Series X|S або PC зі швидкою доставкою та вигідними цінами, а кожному новому користувачу нараховуються бонуси для знижки на першу покупку." ,
        href:'https://l-fut.com',
        img: [
            "img//project/sovaua/site1.png",
            "img//project/sovaua/site2.png",
            "img//project/sovaua/site3.png",
            "img//project/sovaua/site4.png"
        ],

    },
    {
        id:8,
        name: 'School Board',
        mainImg : 'img/project/board/board2.png',
        secondImg : 'img/project/board/board.png' ,
        year: '2022',
        skills: 'HTML , CSS , JS , PHP',
        title: "School Board",
        subtitle: "Landing page, для благодійної організації, яка висвітлює воєнний злочин Росії проти дітей та навчальних закладів в Україні. Дані для візуалізації беруться з Excel таблиць через API, та оновлються кожного дня." ,
        href:'http://children.almenda.org/',
        img: [
            "img//project/sovaua/site1.png",
            "img//project/sovaua/site2.png",
            "img//project/sovaua/site3.png",
            "img//project/sovaua/site4.png"
        ],

    },
    {
        id:1,
        name: 'SOVALINE e-commerce',
        mainImg : 'img/project/sovaua/site1.png',
        secondImg : 'img/project/sovaua/site2.png' ,
        year: '2022',
        skills: 'WordPress' ,
        title: "SOVALINE  e-commerce",
        subtitle: "Інтернет-магазин готових меблів, що співпрацює з меблевими фабриками, доставить у будь-яке місто України за допомогою поштових перевізників.\n" ,
        href:'https://sova-line.com.ua',
        img: [
            "img//project/sovaua/site1.png",
            "img//project/sovaua/site2.png",
            "img//project/sovaua/site3.png",
            "img//project/sovaua/site4.png"
        ],

    },
    {
        id:6,
        name: 'For-Soul',
        year: '2022',
        mainImg : 'img/project/forsoul/forsoul.png',
        skills: 'OpenCart'  ,
        secondImg : 'img/project/forsoul/forsoul2.jpg' ,
        href:'https://www.for-soul.com.ua',
        title: "For-Soul",
        subtitle: "Інтернет-магазин брендової сантехніки з усієї Європи, тут Ви знайдете все для ванної кімнати та санвузлу без зайвих націнок та з актуальними тенденціями.",
        img: [
            "img/project/forsoul/forsoul.png",
            "img/project/forsoul/forsoul2.jpg",
        ],
    },
    {
        id:3,
        name: 'SOVALINE B2B',
        year: '2022',
        skills: 'VUE JS , CSS , PHP',
        mainImg : 'img/project/sovain/sova1.png',
        secondImg : 'img/project/sovain/sova2.png' ,
        href:'https://sovaline.in.ua',
        title: "SOVALINE B2B",
        subtitle: "Сайт для співпраці виробництва меблів з цільовою аудиторією дизайнери-архітектори, на вигідних умовах для самих дизайнерів архітекторів, щоб брати комплексні замовлення меблів під ключ для всієї квартири чи будинку. " ,
        img: [
            "img/project/sovain/sova1.png",
            "img/project/sovain/sova2.png",
            "img//project/sovain/sova3.png",
            "img//project/sovain/sova4.png",
        ],
    },
    {
        id:4,
        name: 'SOVALINE B2C',
        year: '2022',
        skills: 'JS , PHP , HTML , CSS' ,
        mainImg : 'img/project/sovacom/sovacom1.png',
        secondImg : 'img/project/sovacom/sovacom2.png' ,
        href:'https://sovaline.com.ua',
        title: "SOVALINE B2C",
        subtitle: "Сайт для замовлення та виготовлення ексклюзивних меблів будь-якого типу -  напряму через майстрів без посередників." ,
        img: [
            "img/project/sovacom/sovacom1.png",
            "img/project/sovacom/sovacom2.png",
            "img//project/sovacom/sovacom3.png",
            "img//project/sovacom/sovacom4.png",
        ],
    },
    {
        id:5,
        name: 'Windows Service',
        year: '2022',
        mainImg : 'img/project/wsg/wsg1.png',
        skills: 'HTML , CSS , PHP' ,
        secondImg : 'img/project/wsg/wsg2.png' ,
        href:'http://www.wsg.kiev.ua',
        title: "Windows Service",
        subtitle: "Компанія що займається виготовленням та встановленням вікон, а також заміною підвіконників та фурнітури. ",
        img: [
            "img/project/wsg/wsg1.png",
            "img/project/sovain/sova2.png",
            "img//project/sovain/sova3.png",
            "img//project/sovain/sova4.png",
        ],
    },
    // {
    //     id:6,
    //     name: 'Monitoring',
    //     year: '2022',
    //     mainImg : 'img/project/mon/mon1.png',
    //     skills: 'VUE JS , PHP '  ,
    //     secondImg : 'img/project/mon/mon2.png' ,
    //     href:'https://isetmonitoring.com.ua',
    //     title: "Monitoring",
    //     subtitle: "Сайт для моніторингу ринку криптовалют на різних біржах з актуальною інформацією та прогнозом на майбутній стан ринку.",
    //     img: [
    //         "img/project/mon/mon1.png",
    //         "img/project/sovacom/sovacom2.png",
    //         "img//project/sovacom/sovacom3.png",
    //         "img//project/sovacom/sovacom4.png",
    //     ],
    // },
    {
        id:6,
        name: 'SEO',
        year: '2022',
        mainImg : "img/project/seo/seo1.jpg",
        skills: 'WordPress'  ,
        secondImg : "img/project/seo/seo2.jpg" ,
        href:'https://boostmybiz.online',
        title: "SEO",
        subtitle: "SEO компанія, що допоможе з просуванням Вашого сайта в інтернет-просторі. Виконає аналіз наявного сайта, дослідить конкуренцію та підбере стратегію з покращення бізнесу.",
        img: [
            "img/project/seo/seo1.jpg",
            "img/project/seo/seo2.jpg",
        ],
    },
    {
        id:2,
        name: 'RightFood',
        year: '2022',
        skills: 'HTML , CSS , JS , PHP',
        mainImg : 'img/project/rightfood/rightfood1.png',
        secondImg : 'img/project/rightfood/rightfood2.png' ,
        href:'https://right-food.com.ua',
        title: "Right Food",
        subtitle: "Компанія , що займається розробкою, приготуванням та доставкою здорового харчування, розрахують та складуть необхідний для Вас раціон.",
        img: [
            "img/project/rightfood/rightfood1.png",
            "img/project/rightfood/rightfood2.png",
            "img//project/rightfood/roghtfood3.png",
            "img//project/rightfood/rightfood4.png"
        ],
    },

]


export const teamContent = [
    {
        id:10,
        name: "",
        position: "",
        title: "",
        pic: 'team1.png',
        img:'teamBg1.png',
        link:'https://www.linkedin.com/in/andriy-zotov-a832a4105/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
    },
    {
        id:0,
        name: "Andrew Zotov ",
        position: "CEO/Backend Developer",
        title: "Найважливіше завдання цивілізації - навчити людину мислити - Томас Едісон",
        pic: 'team9.png',
        img:'teamBg9.png',
        link:'https://www.linkedin.com/in/andriy-zotov-a832a4105/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',

    },
    {
        id:1,
        name: "Vladyslav Gergel",
        position: "Backend Developer",
        pic: 'team6.png',
        img:'teamBg6.png',
        link:'https://www.linkedin.com/in/vladislav-gergel-31846a1b2/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "Працювати потрібно не 12 годин, а головою – Стів Джобс",

    },
    {
        id:2,
        name: "Bohdan Suhomlinov",
        position: "Devops Engineer",
        pic: 'team10.png',
        img:'teamBg10.png',
        link:'https://www.linkedin.com/in/shellshock-dnull/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "Дайте мені шість годин, щоб зрубати дерево, і я перші чотири проведу заточуючи сокиру  - Авраам Лінкольн",


    },
    {
        id:3,
        name: "Vasiliy Dyachuk ",
        position: "Frontend Developer",
        pic: 'team8.png',
        img:'teamBg8.png',
        link:'https://www.linkedin.com/in/vasua-dyachuk-73a245200/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "Добре сформульована проблема — це наполовину вирішена проблема - Чарльз Кеттерінг",

    },

    {
        id:4,
        name: "Dmitriy Dyachuk ",
        position: "Frontend Developer",
        pic: 'team7.png',
        img:'teamBg7.png',
        link:'https://www.linkedin.com/in/dima-dyachuk/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "Думай не про завдання, а про рішення - Зедд",

    },

    {
        id:5,
        name: "Valeriia Kovtun",
        position: "QA Engineer",
        pic: 'team3.png',
        img:'teamBg3.png',
        link:'https://www.linkedin.com/in/valeriia-kovtun-9935ba19a/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "Кращий спосіб почати робити – перестати говорити і почати робити – Уолт Дісней",

    },

    {
        id:6,
        name: "Natalia Riashko",
        position: "Product Designer",
        pic: 'team5.png',
        img:'teamBg5.png',
        link:'https://www.linkedin.com/in/natalia-riashko/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "В зовнішньому світі ми бачимо лише віддзеркалення свого внутрішнього - Байрон Кейті",

    },
    {
        id:7,
        name: "Angelina Grigorenko ",
        position: "UX/UI Designer",
        pic: 'team4.png',
        img:'teamBg4.png',
        title: "Для того, щоб прийти до справжнього знання, треба спочатку відчути сумніви - Арістотель",
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        link:'https://www.linkedin.com/in/%D0%B0%D0%BD%D0%B3%D0%B5%D0%BB%D0%B8%D0%BD%D0%B0-%D0%B3%D1%80%D0%B8%D0%B3%D0%BE%D1%80%D0%B5%D0%BD%D0%BA%D0%BE-203432158',    },

    {
        id:8,
        name: "Oleksandr Trushyn",
        position: "Frontend Developer",
        pic: 'team2.png',
        img:'teamBg2.png',
        link:'https://www.linkedin.com/in/олександр-tрушин-14880416b/',
        title: "Ніколи не робить помилок лише той, хто не пробує нічого нового - Альберт Ейнштейн",
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',


    },
    {
        id:9,
        name: "Andriy Shumlianskyi",
        position: "Customer Sales",
        pic: 'team11.png',
        img:'teamBg11.png',
        link:'https://www.linkedin.com/in/олександр-tрушин-14880416b/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "365 нових днів, 365 нових шансів"

    },
    {
        id:10,
        name: "Silvana Stakhurska",
        position: "PM Academy",
        pic: 'team12.png',
        img:'teamBg12.png',
        link:'https://www.linkedin.com/in/олександр-tрушин-14880416b/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "Сміливі завжди мають щастя"

    },


];

export const projectsContent = [
    {
        title: "Online store",
        urlTitle: "sova-line.com.ua",
        url: "https://sova-line.com.ua/",
        pics: [
            "sovaua/sovaua1.png",
            "sovaua/sovaua2.png",
            "sovaua/sovaua3.png",
            "sovaua/sovaua4.png",
            "sovaua/sovaua5.png"
        ],
        skills: ["wordpress"]
    },
    {
        title: "Food delivery",
        urlTitle: "right-food.com.ua",
        url: "https://right-food.com.ua/",
        pics: [
            "rightfood/rightfood1.png",
            "rightfood/rightfood2.png",
            "rightfood/rightfood3.png",
            "rightfood/rightfood4.png",
            "rightfood/rightfood5.png"
        ],
        skills: ["js", "mysql", "php"]
    },
    {
        title: "Monitoring",
        urlTitle: "isetmonitoring.com.ua",
        url: "https://isetmonitoring.com.ua/",
        pics: [
            "mon/mon1.png",
            "mon/mon2.png",
            "mon/mon3.png",
        ],
        skills: ["vue", "php" , "redis"]
    },
    {
        title: "Business card",
        urlTitle: "sovaline.in.ua",
        url: "https://sovaline.in.ua/",
        pics: [
            "sovain/sovain1.png",
            "sovain/sovain2.png",
            "sovain/sovain3.png",
            "sovain/sovain4.png",
            "sovain/sovain5.png"
        ],
        skills: ["css", "vue", "php"]
    },
    {
        title: "Furniture for you",
        urlTitle: "sovaline.com.ua",
        url: "https://sovaline.com.ua/",
        pics: [
            "sovacom/sovacom1.png",
            "sovacom/sovacom2.png",
            "sovacom/sovacom3.png"
        ],
        skills: ["css", "php", "js"]
    },
    {
        title: "Super attraction",
        urlTitle: "realmatrix.kiev.ua",
        url: "http://www.realmatrix.kiev.ua/",
        pics: [
            "realmatrix/realmatrix1.png",
            "realmatrix/realmatrix2.png",
            "realmatrix/realmatrix3.png"
        ],
        skills: ["js", "html", "php"]
    },
    {
        title: "Windows Service Group",
        urlTitle: "wsg.kiev.ua",
        url: "http://www.wsg.kiev.ua/",
        pics: [
            "wsg/wsg1.png",
            "wsg/wsg2.png",
            "wsg/wsg3.png"
        ],
        skills: ["js", "bootstrap", "html"]
    },
    {
        title: "Car parts",
        urlTitle: "avtoset.shop",
        url: "https://avtoset.shop/",
        pics: [
            "avtoset/avto1.png",
            "avtoset/avto2.png",
            "avtoset/avto3.png"
        ],
        skills: ["css", "js", "php"]
    },

];



export const processContent = [
    {
        text: "Apple has removed Fortnite from the App Store after the game's developer announced new payment options that allow players to buy in-game credits direct from Epic Games. The new payment option appears to skirt Apple's App Store and Google's Play Store rules, which require Epic to give up a 30% cut of the revenue made through the app.",
        // pic: "https://i.pinimg.com/736x/9b/dd/6e/9bdd6ef64b40375c3bdc4d98fbaaf6ed.jpg",
        pic: "process-1",
    }, 
    {
        text: "Google has removed Fortnite from the App Store after the game's developer announced new payment options that allow players to buy in-game credits direct from Epic Games. The new payment option appears to skirt Apple's App Store and Google's Play Store rules, which require Epic to give up a 30% cut of the revenue made through the app.",
        // pic: "https://miro.medium.com/max/920/1*mog1dBfi2nKfo42qeOEg8Q.jpeg",
        pic: "process-2",
    }, 
    {
        text: "Nintendo has removed Fortnite from the App Store after the game's developer announced new payment options that allow players to buy in-game credits direct from Epic Games. The new payment option appears to skirt Apple's App Store and Google's Play Store rules, which require Epic to give up a 30% cut of the revenue made through the app.",
        //pic: "https://i.guim.co.uk/img/media/20d9ad9ab7006201441a5bfd92ad05ae4dfcd367/0_49_3500_2101/master/3500.jpg?width=700&quality=85&auto=format&fit=max&s=bd13086bae6a07f9e28df3f759493d6d",
        pic: "process-3",
    }, 
    {
        text: "Ubisoft has removed Fortnite from the App Store after the game's developer announced new payment options that allow players to buy in-game credits direct from Epic Games. The new payment option appears to skirt Apple's App Store and Google's Play Store rules, which require Epic to give up a 30% cut of the revenue made through the app.",
        // pic: "https://storage.googleapis.com/nrpassets/uploads/articles/1561468005-1_0q5hLbYECFfgVh4PmegYrg.png",
        pic: "process-4",
    }, 
    {
        text: "Rockstar Games has removed Fortnite from the App Store after the game's developer announced new payment options that allow players to buy in-game credits direct from Epic Games. The new payment option appears to skirt Apple's App Store and Google's Play Store rules, which require Epic to give up a 30% cut of the revenue made through the app.",
        // pic: "https://api.time.com/wp-content/uploads/2016/02/michael-jackson.jpg",
        pic: "process-5",
    }, 
    {
        text: "Crystal Dynamics has removed Fortnite from the App Store after the game's developer announced new payment options that allow players to buy in-game credits direct from Epic Games. The new payment option appears to skirt Apple's App Store and Google's Play Store rules, which require Epic to give up a 30% cut of the revenue made through the app.",
        // pic: "https://i.pinimg.com/736x/9b/dd/6e/9bdd6ef64b40375c3bdc4d98fbaaf6ed.jpg",
        pic: "process-6",
    }, 
    {
        text: "Visceral Games has removed Fortnite from the App Store after the game's developer announced new payment options that allow players to buy in-game credits direct from Epic Games. The new payment option appears to skirt Apple's App Store and Google's Play Store rules, which require Epic to give up a 30% cut of the revenue made through the app.",
        //pic: "https://miro.medium.com/max/920/1*mog1dBfi2nKfo42qeOEg8Q.jpeg",
        pic: "process-7",
    }
];


export const TranslateContact =
    {
        title:'КОНТАКТИ',
        subtitle1:"Ми цінуємо індивідуальність , тому для більш детального прорахунку опиши, що саме тобі треба, можло ти вже знаеш на яких технологія тобі потріб сайт, чи є в тебе",
        subtitle2:"терміну виконня роботи",
        subtitle3:"або якість побаження. Ми відкриті до діалогу і готові відповідсти ",
        subtitle4:"на всі твої запитання.",
        form: 'ФОРМА' ,
        contact: 'КОНТАКТУ' ,
        placeholder1: "Введіть Ваше ім’я",
        placeholder2: "Ваш контакт для зв’язку" ,
        placeholder3 : 'Ваш запит' ,
        address: 'Адреса' ,
        nameAddress: 'м. Львів, Промислова 50/52' ,
        mail : 'Пошта',
        phone:'Телефон',
        button:'ОФОРМИТИ',
        popapTitle : 'Дякую, ваша заявка успішно відправлена!',
        popapSubtitle : 'Наш менеджер зв\'яжеться з вами в найближчий час.'
    }

export const Options = [
    { value: 'Тип сайту', label: 'Тип Сайту' },
    { value: 'Корпоративний / Бізнес сайт', label: 'Корпоративний / Бізнес сайт' },
    { value: 'Інтернет магазин', label: 'Інтернет магазин' },
    { value: 'Сайт візитка', label: 'Сайт візитка' },
    { value: 'Чат – Боти', label: ' Чат–Боти' },
    { value: 'Landing page', label: ' Landing page' },
    { value: 'Редизайн сайту', label: 'Редизайн сайту' },
]

export const HeaderTranslate = {
    news: 'новини',
    academy : 'академія' ,
    project : 'проекти' ,
    technologies: 'технології' ,
    team : 'команда' ,
    services : 'послуги' ,
    contact : 'контакти' ,
    buttonHeader : "ЗВ'ЯЗОК"
}

import React, {useEffect, useState} from "react";
import {teamContent} from '../../mockData';
import {useLocation} from "react-router-dom";
import './TeamPage.css'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {teamContentEng} from "../../mockDataEnd";


const IsetSoft = () => {
    const [slidesBetwen , setSliderBetwen] = useState(40)
    const [slidesWidth , setSliderWidht] = useState(280)
    const [count , setCount] = useState(1)
    const [countPagination , setCountPagination] = useState(0)
    const [teamSliderContent , setTeamSliderContent] = useState(teamContent)
    const [slide , SetSlide] = useState({})
    const [animate , SetAnimate] = useState('swiper-active')
    const [img , setImage] = useState('')
    const [title , setTitle] = useState('команда')
    const loc = useLocation().pathname;

    function animateSwiper(){
        if (animate == 'swiper-active'){
            SetAnimate('swiper-active-second')
        }else {
            SetAnimate('swiper-active')
        }
    }


      function countPlus(){
        setCount( 1 + count)
        setCountPagination(countPagination + 1)
        if (count >= teamSliderContent.length - 1){
            setCount(0)
        }
        if (countPagination >= teamSliderContent.length - 1){
            setCountPagination( 0)
        }
         animateSwiper()
    }

     function countMinus(){
        setCount(count - 1)
        setCountPagination(countPagination - 1)
        if (count <= 0 ){
            setCount(teamSliderContent.length - 1)
        }
        if (countPagination <= 0){
            setCountPagination( teamSliderContent.length -1)
        }
        animateSwiper()
    }

     function setOnClick(i){
        setCount(i + 1)
        setCountPagination(i)
        if (count == teamSliderContent.length - 1){
            setCount(0)
        }
        if (countPagination == teamSliderContent.length - 1){
            setCountPagination( 0)
        }
        animateSwiper()
    }

    const activeSlides = ( countPagination= 0  ) => {
        SetSlide(teamSliderContent[countPagination])
    }
    const activeImg = (countPagination = 0 ) => {
        setImage(teamSliderContent[countPagination].img)
    }

    useEffect(()=>{
        const  header = document.querySelector('header')
        const footerHome = document.querySelector('.footer')
        if (loc == '/team-work'){
            header.style.display = 'flex' ;
            footerHome.style.display = 'flex';
        }

        activeSlides(countPagination)
        activeImg(countPagination)

        if (localStorage.getItem('language') == 'en'){
            setTeamSliderContent(teamContentEng)
            setTitle('team')
        }else {
            setTeamSliderContent(teamContent)
            setTitle('Команда')
        }
    })

    const titleImg = (
      <div className={'team-info'}>
          <div className="team-title">
              <h1>Isetsoft</h1>
              <h2>{title}</h2>
          </div>

          <div className="team-subtitle">
              <p className={'team-name'}>{slide.name}</p>
              <p className={'team-position'}>{slide.position}</p>
              {slide.title}
          </div>
          <div className="team-networks">
              <a target={"_blank"} href={slide.lin}><img src="./img/icons/linkedinIcons.svg" alt=""/></a>
              <a target={"_blank"} href="https://jobs.dou.ua/companies/isetsoft/"> <img src="./img/icons/douIcons.svg" alt=""/></a>
          </div>
      </div>
    );

  const teamSlider = (
      <div className={'team-page-slider'}>
          <div className="team-slider-container">
              <div className="team-slider-wrapper" style={{marginLeft: '-' + (count ) * (slidesWidth + slidesBetwen) + 'px'}}>
                  {teamSliderContent.map((slides , i ) =>
                      <div key={i} onClick={()=> setOnClick(i)} className={'swiper-slide'} style={{marginRight: slidesBetwen + 'px'}}>
                          <img src={"./img/team/" + slides.pic}  alt=""/>
                      </div>
                  )}
              </div>
          </div>
          <div className="pagination-containers">
              <div className="pagination-container">
                  <div className="pagination-bullet">
                      <div className="pagination-wrapper" style={count > 1 ? {marginLeft: '-' + (countPagination - 1) * (18) + 'px'} :{marginLeft: '-' + (countPagination) * (0) + 'px'}}>
                          {teamSliderContent.map((slide , i )=>
                              <div key={i} id={i} className={ countPagination == i ? ' bullet active-bullet': 'bullet'  }>
                              </div>
                          )}
                      </div>
                  </div>
                  <div className="swiper-pagination-academy">
                      <p style={ countPagination > 8 ? {display:"block"} : {display:"none"}}>{countPagination + 1}</p>
                      <p style={ countPagination < 9 ? {display:"block"} : {display: "none"}}>0{countPagination + 1}</p>
                      <span>
                                        \{teamSliderContent.length}
                                    </span>
                  </div>
              </div>
              <div className="pagination-container">
                  <div onClick={countPlus} className="swiper-next"><img src="./img/icons/sliderNext.svg" alt=""/></div>
                  <div onClick={countMinus} className="swiper-prev"> <img src="./img/icons/sliderPrev.svg" className="slider-prev" alt=""/></div>
              </div>
          </div>
      </div>

  )

    const activeImage = (
        <div className={"team-img " + animate}>
            <img src={'./img/team/' + img} alt="img"/>
            <div className={'team-disactive'}><img src="./img/team/teamBg1.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg9.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg6.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg10.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg8.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg7.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg3.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg5.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg4.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg2.png" alt=""/></div>
            <div className={'team-disactive'}><img src="./img/team/teamBg11.png" alt=""/></div>
        </div>
    )

  return (
      <div className={'views-container'}>
              <div className="wrapper-team wrapper-mobile page-animation">
                  <div className={'team-page'}>
                      {titleImg}
                      {activeImage}
                      <div className="pagination-mobile">
                          <div className="pagination-container">
                              <div onClick={countMinus} className="swiper-prev"> <img src="./img/icons/sliderPrev.svg" className="slider-prev" alt=""/></div>
                              <div className="pagination-bullet">
                                  <div className="pagination-wrapper" style={countPagination > 1 ? {marginLeft: '-' + (countPagination - 1) * (18) + 'px'} :{marginLeft: '-' + (countPagination) * (0) + 'px'}}>
                                      {teamSliderContent.map((slide , i )=>
                                          <div key={i} id={i} className={ countPagination == i ? ' bullet active-bullet': 'bullet'  }>
                                          </div>
                                      )}
                                  </div>
                              </div>
                              <div onClick={countPlus} className="swiper-next"><img src="./img/icons/sliderNext.svg" alt=""/></div>
                          </div>
                          <div className="pagination-container">
                              <div className="swiper-pagination-academy">
                                  <p style={ countPagination > 8 ? {display:"block"} : {display:"none"}}>{countPagination + 1}</p>
                                  <p style={ countPagination < 9 ? {display:"block"} : {display: "none"}}>0{countPagination + 1}</p>
                                  <span>
                                 \{teamSliderContent.length}
                                </span>
                              </div>
                          </div>
                      </div>

                      {teamSlider}
                  </div>
              </div>
      </div>

  )
}


export default IsetSoft


import React, {useEffect, useRef, useState} from 'react';
import './academy.css'
import {AboutInfo, sliderSoft, teamContent} from "../../mockData"
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {AboutInfoEng, sliderSoftEng} from "../../mockDataEnd";
import {useLocation} from "react-router-dom";

const Academy = () => {

    const [windowWidth, setWindowWidth] = useState(1440)
    const [windowHeight, setWindowHeight] = useState(0)


    const [titleCount , SetTitleCount] = useState(0)
    const [count , setCount] = useState(1)
    const [countPagination , setCountPagination] = useState(0)
    const [spaceBetween , setSpaceBetween] = useState(40)
    const [slideWidth , setSlideWidth] = useState(280)
    const [sliderContent , setSliderContent] = useState([])
    const [mainImg , setMainImg] = useState([])
    const [mainLink , setMainLink] = useState('')
    const [animation , setAnimation] = useState('active')
    const [subtitle , setSubTitle ] = useState('АКАДЕМІЯ ')




    const mainImages = (countPagination = 0) =>{
        setMainImg(sliderSoft[countPagination].images)
    };
    const link = (countPagination = 0) =>{
        setMainLink(sliderSoft[countPagination].link)
    };


    function animationImg(){
        if (animation == 'active'){
            setAnimation('activeSecond')
        }else {
            setAnimation('active')
        }
    }

    function nextSlide(){
        setCount(count + 1)
        setCountPagination(countPagination + 1)
        if (count == mainImg.length - 1){
            setCount(0)
        }
        if (countPagination == mainImg.length - 1){
            setCountPagination( 0)
        }
        animationImg()
    }
    function nextOnClick(i){
        setCount(i + 1)
        setCountPagination(i)
        if (count == mainImg.length - 1){
            setCount(0)
        }
        if (countPagination == mainImg.length - 1){
            setCountPagination( 0)
        }
        animationImg()
    }

    function prevSlide(){
        setCount(count - 1)
        setCountPagination(countPagination - 1)
        if (count == 0 ){
            setCount(mainImg.length - 1)
        }
        if (countPagination == 0){
            setCountPagination( mainImg.length -1)
        }
        animationImg()
    }

    function setTitle(i){
        SetTitleCount(i)
        setCount(1)
        setCountPagination(0)
    }

    const loc = useLocation().pathname;

    const onResize = (windowWidth, windowHeight) => {
        setWindowWidth(windowWidth);
        setWindowHeight(windowHeight)
    }

    useEffect(()=>{
        mainImages(titleCount)
        link(titleCount)
        document.addEventListener('resize', onResize(window.innerWidth, window.innerHeight));

        const  header = document.querySelector('header')
        const  footerHome = document.querySelector('.footer')
        if (loc == '/academy'){
            header.style.display = 'flex' ;
            footerHome.style.display = 'flex';
        }

        if (windowWidth < 500){
            console.log('323')
        }
        if (localStorage.getItem('language') == 'en'){
            setSliderContent(sliderSoftEng)
            setSubTitle('Academy')
        }else {
            setSliderContent(sliderSoft)
            setSubTitle('АКАДЕМІЯ')
        }
    })

    const sliderAcademy = (
      <div className="swiper-containter">
          <div className="swiper-wrapper" style={{marginLeft: '-' + (count) * (spaceBetween + slideWidth) + 'px'}}>
              {mainImg.map((slide , i) =>
                  <div key={i} onClick={()=> nextOnClick(i)}  className="swiper-slide">
                      <img src={"./img/academy/"+ slide } alt=""/>
                  </div>
              )}

          </div>

          <div className="pagination-containers">
                <div className="pagination-container">
                    <div className="pagination-bullet">
                        <div className="pagination-wrapper" style={countPagination > 1 ? {marginLeft: '-' + (countPagination - 1) * (18) + 'px'} :{marginLeft: '-' + (countPagination) * (0) + 'px'}}>
                            {mainImg.map((slide , i )=>
                                <div key={i} id={i} className={ countPagination == i ? ' bullet active-bullet': 'bullet'  }>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="swiper-pagination-academy">
                        <p>0{countPagination + 1}</p>
                        <span>
                            \0{mainImg.length}
                        </span>
                    </div>
                </div>
                <div className="pagination-container">
                    <div onClick={nextSlide} className="swiper-next"><img src="./img/icons/sliderNext.svg" alt=""/></div>
                    <div onClick={prevSlide} className="swiper-prev"> <img src="./img/icons/sliderPrev.svg" className="slider-prev" alt=""/></div>
                </div>
          </div>
      </div>
    );

    const sliderAcademyTitle = (
        <div className="swiper-title-container">
            <div className="swiper-title-wrapper" style={titleCount > sliderContent.length ? { marginTop:'-' + ( titleCount - 1 ) * 80 + 'px'} : { marginTop:'-' + ( titleCount ) * 0 + 'px'} } >
                {sliderContent.map((slide, i) =>
                    <div key={i} onClick={ ()=> setTitle(i) } className={ titleCount == i ? "title-slide swiper-title-active " : 'title-slide '}>
                        <div className="slider-active-background"></div>
                        <p>0{slide.id}</p>
                        <div className="title-slide-content">
                            <span>{slide.title}</span>
                            <h4>{slide.subtitle}</h4>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );


    const sliderAcademyImg = (
        <div className="main-img-container">
                <div className={"main-img " + animation}>
                    <a target={'_blank'} className={'link-image'} href={mainLink}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                                <path fill-rule="evenodd"
                                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                            </svg>
                        </span>
                        <img src={"./img/academy/"+ mainImg[countPagination]} alt=""/>
                    </a>
                </div>
        </div>
    );


    return (
        <div className={'views-container'}>
                <div className="wrapper-swiper-academy page-animation">
                    <div className="academy-title">
                        <h1>Isetsoft</h1>
                        <h2>{subtitle}</h2>
                        {sliderAcademyTitle}
                    </div>
                    <img className={'img-loaded'} src="./img/academy/academy2.png" alt=""/>
                    <img className={'img-loaded'} src="./img/academy/academy1.png" alt=""/>
                    <img className={'img-loaded'} src="./img/academy/academy3.png" alt=""/>

                    {sliderAcademyImg}
                    <div className="pagination-mobile">
                        <div className="pagination-container">
                            <div onClick={prevSlide} className="swiper-prev"> <img src="./img/icons/sliderPrev.svg" className="slider-prev" alt=""/></div>
                            <div className="pagination-bullet">
                                <div className="pagination-wrapper" style={countPagination > 1 ? {marginLeft: '-' + (countPagination - 1) * (18) + 'px'} :{marginLeft: '-' + (countPagination) * (0) + 'px'}}>
                                    {mainImg.map((slide , i )=>
                                        <div key={i} id={i} className={ countPagination == i ? ' bullet active-bullet': 'bullet'  }>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div onClick={nextSlide} className="swiper-next"><img src="./img/icons/sliderNext.svg" alt=""/></div>
                        </div>
                        <div className="pagination-container">
                            <div className="swiper-pagination-academy">
                                <p>0{countPagination + 1}</p>
                                <span>
                                 \0{mainImg.length}
                                </span>
                            </div>
                        </div>
                    </div>
                    {sliderAcademy}
                    <div className="link-wrapper">
                        <a target="blank" href="https://www.facebook.com/Isetsoft-101178219433926">
                            <img  src="./img/icons/facIcon.svg" alt=""/>
                        </a>
                        <a target="blank" href="https://t.me/iset_order_bot">
                            <img src="./img/icons/telIcon.svg" alt=""/>
                        </a>
                    </div>
                </div>
        </div>
    );
};

export default Academy;
import React, {useState,useRef, useEffect  } from "react";
import {BrowserRouter, Redirect, Route, Switch } from "react-router-dom";


import HomePage from "./views/Home/HomePage";
import IsetSoft from "./views/IsetSoft/IsetSoft";
import AboutPage from "./views/About/AboutPage";
import ContactsPage from "./views/Contacts/ContantsPage";
import SkillsPage from "./views/Skills/SkillsPage";
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer";
import './App.css'
import NET from "vanta/dist/vanta.net.min";
import * as THREE from "three";
import IsetSlider from "./views/IsetSlider/IsetSlider";
import Academy from "./views/Academy/Academy";
import NewsPage from "./views/News/NewsPage";
import Modal from "./views/Contacts/Modal";


function App() {
  const [active, setActive] = useState(true);
  const [innerHeight, setInnerHeigth] = useState('')
  const [innerWidth, setInnerWidth] = useState('')
  const [loading , setLoading] = useState(true)
  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);

  const setWindowChars = (width, heigth) => {
    setInnerHeigth(heigth);
    setInnerWidth(width);
  }


    useEffect(() => {
    localStorage.removeItem('type')
    setLoading(false)

    window.addEventListener('resize', () => setWindowChars(window.innerWidth, window.innerHeight));
    if (!vantaEffect) {
      setVantaEffect(
          NET({
                el: vantaRef.current,
                THREE: THREE,
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                color: 0x382561,
                backgroundColor: 0xc0214,
                points: 15,
                maxDistance: 20.00,
                spacing: 15.00
          })
      );
    }

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);


  return (
      <div id="root" ref={vantaRef}>
          {
              loading ?
                  <div style={{width: ' 100%' , position: 'fixed', height:'100%' }}>
                      <img style={{width: ' 100%' , height:'100%'}} src="/img/canvas/backgroundiset.png" alt=""/>
                  </div>
                  :
                  <BrowserRouter>
                      {active ?  <Header /> : ''}
                          <Switch>
                              <Route exact path="/" component={HomePage} />
                              <Route exact path="/news" component={NewsPage}/>
                              <Route exact path="/academy" component={Academy}/>
                              {/*<Route exact path="/iset-slider" component={IsetSlider}/>*/}
                              <Route exact path="/projects" component={IsetSlider}/>
                              <Route exact path="/skills" component={SkillsPage}/>
                              <Route exact path="/team-work" component={IsetSoft}/>
                              <Route exact path="/about" component={AboutPage}/>
                              <Route component={ContactsPage}>
                                  <Route path="/contacts"  component={ContactsPage}/>
                                  <Route path="/contacts/success" component={Modal}></Route>
                              </Route>
                              <Redirect to="/"/>
                          </Switch>
                      {active ?  <Footer /> : ''}
                  </BrowserRouter>
          }

      </div>
  );
}

export default App;




export const sliderSoftEng = [
    {
        id: 1,
        title:'course:',
        subtitle:'Frontend developer',
        img:'academy2.png',
        link:'https://www.isetsoft-academy.com.ua/course/frontend',
        images:[
            'academy2.png',
            'academy4.png',
            'academy5.png'
        ],
    },
    {
        id: 2,
        title:'course:',
        subtitle:'UX/UI Design',
        img:'academy1.png',
        link:'https://www.isetsoft-academy.com.ua/course/design',
        images:[
            'academy1.png',
            'academy8.png',
            'academy9.png',
            'academy10.png'
        ],
    },
    {
        id: 3,
        title:'course:',
        subtitle:'Backend developer',
        img:'academy3.png',
        link:'https://www.isetsoft-academy.com.ua/course/backend',
        images:[
            'academy3.png',
            'academy6.png',
            'academy7.png',
        ],
    }

];

export const newsSoftEng = [
    {
        id: 5,
        title:'March',
        year:2023,
        news:[
            {
                id: 0 ,date:'29.03.2023',link:'(Details by phone 0681628957)', dateSlider:'march 29' , titleNews: '‘Online meeting‘' , subtitleNews : 'You will learn the features and advantages of the profession, get a clear understanding of what is the IT industry. The lecturers will tell you everything about IT as no one else (Details by phone 0681628957).', img: 'news29.png'
            },
            {
                id: 1 ,date:'31.03.2023',link:'Pre-registration is required by calling 0681628957.', dateSlider:'march 31' , titleNews: '‘Career Evening‘' , subtitleNews : "We will tell you why it's worth starting your journey into the IT industry right now and why it's worth with ISETSOFT Academy. We will take you to the IT world and treat you to delicious coffee.", img: "news31.png"
            },
        ]
    },
    {
        id: 6,
        title:'April',
        year:2023,
        news:[
            {
                id: 2 ,date:'01.04.2023',link:'', dateSlider:'1 april' , titleNews: 'Start of the course ‘UX/UI Design‘' , subtitleNews : 'Lecturer Natalia Riashko will give you a clear understanding of the concept of UX/UI design and explain why creativity takes up only 10% of the work.\n' + 'In the program: Wireframing, User interviews, CJM, User persona, User-flow, MVP.', img: 'newsUx.png'
            },
            {
                id: 0 ,date:'10.04.2023',link:'', dateSlider:'april 10' , titleNews: 'Start of the course ‘Backend developer‘' , subtitleNews : 'Lecturer Andrew Zotov will explain in detail all the principles of working with the server.\n' + 'In the program: Html, CSS, PHP learning, MySQL server.', img: 'newsback.png'
            },
            {
                id: 1 ,date:'10.04.2023',link:'', dateSlider:'april 31' , titleNews: 'Start of the course ‘Frontend developer‘' , subtitleNews : 'Lecturer Dmitriy Dyachuk will teach you how to understand and write HTML, CSS, and JavaScript from the beginning.\n' + 'In the program: ClassList and event delegation, Json format, Ajax, Fetch...', img: 'newsFront.png'
            },
        ]
    },
    {
        id: 3,
        title:'November',
        year:2022,
        news:[
            {
                id: 0 ,date:'15.11.2022',link:'', dateSlider:'15 November' , titleNews: 'Webinar ‘PHP framework Laravel and its features’' , subtitleNews : "We will find out why Laravel is that popular among developers and how easy and fast it is to create a project, using basic features of Laravel.", img: 'news3.png'
            },
        ]
    },
    {
        id: 2,
        year:2022,
        title:'October',
        news:[
            {
                id: 0 ,date:'25.10.2022',link:'', dateSlider:'25 October' , titleNews: 'Webinar ‘IT from scratch’' , subtitleNews : "We will explore areas in the IT sector, talk about perspectives, look at the entry level and what is necessary for that.", img: 'news4.png'
            },
        ]
    },
    {
        id: 1,
        title:'September',
        year:2022,
        news:[
            {
                id: 0 , date:'10.09.2022',link:'',  dateSlider:'10 September' , titleNews: "Webinar ‘Difference between Junior and Trainee’" , subtitleNews : " We will find out who is a Junior and a Trainee. Why in other IT companies you are a Middle, but in others you are a Junior. Switching from one position to another, understanding your level of skill." , img: 'news1.png'
            },
            {
                id: 1 ,date:'17.09.2022',link:'', dateSlider:'17 September' , titleNews: 'Webinar ’Outsourcing & Freelance’' , subtitleNews : "We will look at the specifics of the work for newcomers in IT. Where and how to find first orders, work with clients, awareness of deadlines" ,  img: 'news2.png'
            },
        ]

    },

];





export const AboutInfoEng = [
    {
        id:1,
        title: 'Landing page',
        contact:'Landing page' ,
        price:'from 500 $',
        subtitle: 'Landing page or simply Landing is a unique type of one-page website.',
        info: 'Its task is to get acquainted with your company and its services. The function of landing is to encourage the visitor to take some kind of "target" action (subscription to the newsletter, price or catalog requests, website signup, purchase).'
    },
    {
        id:2,
        title: 'An online store' ,
        contact:'Інтернет магазин' ,
        price:'from 1 000 $',
        subtitle: 'An online store or e-commerce is the purchase and sale of goods or services online.',
        info: 'Are you an owner of an Instagram store and you are tired of taking standard orders through Direct? ' +
            'Are you a new cosmetics brand and want your clients to order in two clicks online? Online store is a perfect option and convenient tool for business optimization.'

    },
    {
        id:3,
        title: 'Corporate websites' ,
        contact:'Корпоративний / Бізнес сайт' ,
        price:'from 600 $',
        subtitle: 'A corporate website is an internet platform for business which consists of useful info about the company, its goods and services, news, discounts.',
        info:'Improves the image of the organization, increases sales and makes communication with clients or partners easier, allows you to manage site content, publish news/articles, get feedback from users, place a catalog of goods and services, post information about representative offices, branches, offices, vacancies etc.'
    },
    {
        id:4,
        title: 'Chatbots' ,
        contact:'Чат – Боти' ,
        price:'from 200 $',
        subtitle: 'Chatbots is a convenient tool of automatisation powered by artificial intelligence which replaces the manager.',
        info:'- Creating bots (shopping, financial, for messengers \n' +
            '   and newsletter, bots for stock exchange and traders\n' +
            '- Collecting client’s data\n' +
            '- Integration of payment methods\n' +
            '- Autoregistrators and autopostings\n' +
            '- Developing parsers, automatics data collection on the Internet'
    },
    {
        id:5,
        title: 'Website redesign' ,
        contact:'Редизайн сайту' ,
        price:'from 200 $',
        subtitle: 'Website redesign is not only visual design.',
        info:'Redesign involves changing the site structure, improvement of functionality and optimization of web resources for the needs of the target audience. \n' +
            'The need for redesign arises if the website no longer performs the tasks, then the analysis of the website is done and updates for new market requirements.',
    },
    {
        id:6,
        title: 'Business website' ,
        contact:'Корпоративний / Бізнес сайт' ,
        price:'from 700 $',
        subtitle: 'Business website is a multi page site which fits small and medium businesses.',
        info:"Its mission is to form a company's image in the online environment, provide information about services and features of the company, attract new customers and partners."
    },

];

export const NewSliderEng = [
    {
        id:9,
        name: 'L-fut',
        mainImg : 'img/project/lfut/lfut2.png',
        secondImg : 'img/project/lfut/lfut1.png' ,
        year: '2022',
        skills: 'Laravel , JS , Css',
        title: "L-fut",
        subtitle: "A platform where you can buy FIFA 23 Coins for PlayStation 4, PlayStation 5, Xbox One, Xbox Series X|S or PC with fast delivery and good prices, and every new user gets bonuses for a discount on their first purchase." ,
        href:'https://l-fut.com',
        img: [
            "img//project/sovaua/site1.png",
            "img//project/sovaua/site2.png",
            "img//project/sovaua/site3.png",
            "img//project/sovaua/site4.png"
        ],

    },
    {
        id:8,
        name: 'School Board',
        mainImg : 'img/project/board/board2.png',
        secondImg : 'img/project/board/board.png' ,
        year: '2022',
        skills: 'HTML , CSS , JS , PHP',
        title: "School Board",
        subtitle: "Landing page for a charitable organization that highlights Russia's war crimes against children and educational institutions in Ukraine. Data for visualization is taken from Excel tables via API, and is updated every day." ,
        href:'http://children.almenda.org/',
        img: [
            "img//project/sovaua/site1.png",
            "img//project/sovaua/site2.png",
            "img//project/sovaua/site3.png",
            "img//project/sovaua/site4.png"
        ],

    },
    {
        id:1,
        name: 'SOVALINE e-commerce',
        mainImg : 'img/project/sovaua/site1.png',
        secondImg : 'img/project/sovaua/site2.png' ,
        year: '2022',
        skills: 'WordPress' ,
        title: "SOVALINE  e-commerce",
        subtitle: "An online store of Ready-made furniture, which cooperates with furniture factories, deliver to any city/town of Ukraine with the help of postal carriers." ,
        href:'https://sova-line.com.ua',
        img: [
            "img//project/sovaua/site1.png",
            "img//project/sovaua/site2.png",
            "img//project/sovaua/site3.png",
            "img//project/sovaua/site4.png"
        ],

    },
    {
        id:6,
        name: 'For-Soul',
        year: '2022',
        mainImg : 'img/project/forsoul/forsoul.png',
        skills: 'OpenCart'  ,
        secondImg : 'img/project/forsoul/forsoul2.jpg' ,
        href:'https://www.for-soul.com.ua',
        title: "For-Soul",
        subtitle: "An online store of branded plumbing from the whole Europe, you will find everything for your bathroom or sanitary block without any extra charge and with the current trends.",
        img: [
            "img/project/forsoul/forsoul.png",
            "img/project/forsoul/forsoul2.jpg",
        ],
    },
    {
        id:3,
        name: 'SOVALINE B2B',
        year: '2022',
        skills: 'VUE JS , CSS , PHP',
        mainImg : 'img/project/sovain/sova1.png',
        secondImg : 'img/project/sovain/sova2.png' ,
        href:'https://sovaline.in.ua',
        title: "SOVALINE B2B",
        subtitle: "The website for cooperation of furniture manufacturing with the target audience of designers-architects, on favorable terms for those designers-architects to take complex orders of furniture on a turnkey basis for your apartment or house." ,
        img: [
            "img/project/sovain/sova1.png",
            "img/project/sovain/sova2.png",
            "img//project/sovain/sova3.png",
            "img//project/sovain/sova4.png",
        ],
    },
    {
        id:4,
        name: 'SOVALINE B2C',
        year: '2022',
        skills: 'JS , PHP , HTML , CSS' ,
        mainImg : 'img/project/sovacom/sovacom1.png',
        secondImg : 'img/project/sovacom/sovacom2.png' ,
        href:'https://sovaline.com.ua',
        title: "SOVALINE B2C",
        subtitle: "The website for placing the order and manufacturing exclusive furniture of any kind/style from craftsmen without intermediaries." ,
        img: [
            "img/project/sovacom/sovacom1.png",
            "img/project/sovacom/sovacom2.png",
            "img//project/sovacom/sovacom3.png",
            "img//project/sovacom/sovacom4.png",
        ],
    },
    {
        id:5,
        name: 'Windows Service',
        year: '2022',
        mainImg : 'img/project/wsg/wsg1.png',
        skills: 'HTML , CSS , PHP' ,
        secondImg : 'img/project/wsg/wsg2.png' ,
        href:'http://www.wsg.kiev.ua',
        title: "Windows Service",
        subtitle: "The company is engaged in manufacturing and installing the windows, window sill and hardware replacement.",
        img: [
            "img/project/wsg/wsg1.png",
            "img/project/sovain/sova2.png",
            "img//project/sovain/sova3.png",
            "img//project/sovain/sova4.png",
        ],
    },
    {
        id:6,
        name: 'SEO',
        year: '2022',
        mainImg : "img/project/seo/seo1.jpg",
        skills: 'WordPress'  ,
        secondImg : "img/project/seo/seo2.jpg" ,
        href:'https://boostmybiz.online',
        title: "SEO",
        subtitle: "SEO is the company which will give a helping hand with the promotion of your website on the Internet. It will do the analysis of an existing website, monitor your competitors and choose the strategy to improve your business.",
        img: [
            "img/project/seo/seo1.jpg",
            "img/project/seo/seo2.jpg",
        ],
    },
    {
        id:2,
        name: 'RightFood',
        year: '2022',
        skills: 'HTML , CSS , JS , PHP',
        mainImg : 'img/project/rightfood/rightfood1.png',
        secondImg : 'img/project/rightfood/rightfood2.png' ,
        href:'https://right-food.com.ua',
        title: "Right Food",
        subtitle: "A company that specializes in developing, cooking and delivering healthy nutrition, they will calculate and make a proper diet for you.",
        img: [
            "img/project/rightfood/rightfood1.png",
            "img/project/rightfood/rightfood2.png",
            "img//project/rightfood/roghtfood3.png",
            "img//project/rightfood/rightfood4.png"
        ],
    },

]


export const teamContentEng = [
    {
        id:10,
        name: "",
        position: "",
        title: "",
        pic: 'team1.png',
        img:'teamBg1.png',
        link:'https://www.linkedin.com/in/andriy-zotov-a832a4105/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
    },
    {
        id:0,
        name: "Andrew Zotov ",
        position: "CEO/Backend Developer",
        title: "The most important task of civilization to teach a person to think - Thomas Edison",
        pic: 'team9.png',
        img:'teamBg9.png',
        link:'https://www.linkedin.com/in/andriy-zotov-a832a4105/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',

    },
    {
        id:1,
        name: "Vladyslav Gergel",
        position: "Backend Developer",
        pic: 'team6.png',
        img:'teamBg6.png',
        link:'https://www.linkedin.com/in/vladislav-gergel-31846a1b2/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "You have to work not 12 hours, but with your head - Steve Jobs",

    },
    {
        id:2,
        name: "Bohdan Suhomlinov",
        position: "Devops Engineer",
        pic: 'team10.png',
        img:'teamBg10.png',
        link:'https://www.linkedin.com/in/shellshock-dnull/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "Give me six hours to chop down a tree and I will spend the first four sharpening the axe - Abraham Lincoln",


    },
    {
        id:3,
        name: "Vasiliy Dyachuk ",
        position: "Frontend Developer",
        pic: 'team8.png',
        img:'teamBg8.png',
        link:'https://www.linkedin.com/in/vasua-dyachuk-73a245200/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "A problem well-stated is half-solved - Charles Kettering",

    },

    {
        id:4,
        name: "Dmitriy Dyachuk ",
        position: "Frontend Developer",
        pic: 'team7.png',
        img:'teamBg7.png',
        link:'https://www.linkedin.com/in/dima-dyachuk/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "Think of the solution not the problem - Zedd ",

    },

    {
        id:5,
        name: "Valeriia Kovtun",
        position: "QA Engineer",
        pic: 'team3.png',
        img:'teamBg3.png',
        link:'https://www.linkedin.com/in/valeriia-kovtun-9935ba19a/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "The way to get started is to quit talking and begin doing - Walt Disney",

    },

    {
        id:6,
        name: "Natalia Riashko",
        position: "Product Designer",
        pic: 'team5.png',
        img:'teamBg5.png',
        link:'https://www.linkedin.com/in/natalia-riashko/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "We're not meeting anything but thoughts. The external is the internal projected - Byron Katie",

    },
    {
        id:7,
        name: "Angelina Grigorenko ",
        position: "UX/UI Designer",
        pic: 'team4.png',
        img:'teamBg4.png',
        title: "In order to come to true knowledge, one must first feel doubts - Aristotle",
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        link:'https://www.linkedin.com/in/%D0%B0%D0%BD%D0%B3%D0%B5%D0%BB%D0%B8%D0%BD%D0%B0-%D0%B3%D1%80%D0%B8%D0%B3%D0%BE%D1%80%D0%B5%D0%BD%D0%BA%D0%BE-203432158',    },

    {
        id:8,
        name: "Oleksandr Trushyn",
        position: "Frontend Developer",
        pic: 'team2.png',
        img:'teamBg2.png',
        link:'https://www.linkedin.com/in/олександр-tрушин-14880416b/',
        title: "A person who never made a mistake never tried anything new - Albert Einstein",
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',


    },
    {
        id:9,
        name: "Andriy Shumlianskyi",
        position: "Customer Sales",
        pic: 'team11.png',
        img:'teamBg11.png',
        link:'https://www.linkedin.com/in/олександр-tрушин-14880416b/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "365 new days, 365 new chances"

    },
    {
        id:10,
        name: "Silvana Stakhurska",
        position: "PM Academy",
        pic: 'team12.png',
        img:'teamBg12.png',
        link:'https://www.linkedin.com/in/олександр-tрушин-14880416b/',
        lin:'https://www.linkedin.com/company/isetsoft?trk=public_profile_topcard-current-company',
        title: "The brave always have happiness"

    },


];

export const projectsContent = [
    {
        title: "Online store",
        urlTitle: "sova-line.com.ua",
        url: "https://sova-line.com.ua/",
        pics: [
            "sovaua/sovaua1.png",
            "sovaua/sovaua2.png",
            "sovaua/sovaua3.png",
            "sovaua/sovaua4.png",
            "sovaua/sovaua5.png"
        ],
        skills: ["wordpress"]
    },
    {
        title: "Food delivery",
        urlTitle: "right-food.com.ua",
        url: "https://right-food.com.ua/",
        pics: [
            "rightfood/rightfood1.png",
            "rightfood/rightfood2.png",
            "rightfood/rightfood3.png",
            "rightfood/rightfood4.png",
            "rightfood/rightfood5.png"
        ],
        skills: ["js", "mysql", "php"]
    },
    {
        title: "Monitoring",
        urlTitle: "isetmonitoring.com.ua",
        url: "https://isetmonitoring.com.ua/",
        pics: [
            "mon/mon1.png",
            "mon/mon2.png",
            "mon/mon3.png",
        ],
        skills: ["vue", "php" , "redis"]
    },
    {
        title: "Business card",
        urlTitle: "sovaline.in.ua",
        url: "https://sovaline.in.ua/",
        pics: [
            "sovain/sovain1.png",
            "sovain/sovain2.png",
            "sovain/sovain3.png",
            "sovain/sovain4.png",
            "sovain/sovain5.png"
        ],
        skills: ["css", "vue", "php"]
    },
    {
        title: "Furniture for you",
        urlTitle: "sovaline.com.ua",
        url: "https://sovaline.com.ua/",
        pics: [
            "sovacom/sovacom1.png",
            "sovacom/sovacom2.png",
            "sovacom/sovacom3.png"
        ],
        skills: ["css", "php", "js"]
    },
    {
        title: "Super attraction",
        urlTitle: "realmatrix.kiev.ua",
        url: "http://www.realmatrix.kiev.ua/",
        pics: [
            "realmatrix/realmatrix1.png",
            "realmatrix/realmatrix2.png",
            "realmatrix/realmatrix3.png"
        ],
        skills: ["js", "html", "php"]
    },
    {
        title: "Windows Service Group",
        urlTitle: "wsg.kiev.ua",
        url: "http://www.wsg.kiev.ua/",
        pics: [
            "wsg/wsg1.png",
            "wsg/wsg2.png",
            "wsg/wsg3.png"
        ],
        skills: ["js", "bootstrap", "html"]
    },
    {
        title: "Car parts",
        urlTitle: "avtoset.shop",
        url: "https://avtoset.shop/",
        pics: [
            "avtoset/avto1.png",
            "avtoset/avto2.png",
            "avtoset/avto3.png"
        ],
        skills: ["css", "js", "php"]
    },

];


export const TranslateContactEng =
    {
        title:'Contacts',
        subtitle1:"We respect individuality, therefore for a more detailed calculation what exactly you need, maybe you already know which type of technologies you need for a website or you have the deadline or wishes",
        subtitle2:"",
        subtitle3:"We are open to a dialogue and ready to give answers",
        subtitle4:"to all of your questions.",
        form: 'CONTACT' ,
        contact: 'FORM' ,
        placeholder1: "Enter your name",
        placeholder2: "Your contact for communication" ,
        placeholder3 : 'Your request' ,
        address: 'Address' ,
        nameAddress: 'Lviv, Promyslova 50/52' ,
        mail : 'Mail',
        phone:'Phone',
        button:'To check out',
        popapTitle : 'Thank you, your application has been sent successfully!',
        popapSubtitle : 'Our manager will contact you shortly.'
    }

export const OptionsEng = [
    { value: 'Тип сайту', label: 'Site type' },
    { value: 'Корпоративний / Бізнес сайт', label: 'Corporate websites  / Business website' },
    { value: 'Інтернет магазин', label: 'An online store' },
    { value: 'Сайт візитка', label: 'Business card site' },
    { value: 'Чат – Боти', label: 'Chatbots' },
    { value: 'Landing page', label: ' Landing page' },
    { value: 'Редизайн сайту', label: 'Website redesign' },
]

export const HeaderTranslateEng = {
    news: 'NEWS',
    academy : 'ACADEMY' ,
    project : 'PROJECTS' ,
    technologies: 'technologies' ,
    team : 'team' ,
    services : 'services' ,
    contact : 'contact' ,
    buttonHeader : "contact us"
}
import React , {useState , useRef, useEffect} from 'react';
import {AboutInfo, newsSoft} from "../../mockData";
import './news.css'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {AboutInfoEng, newsSoftEng} from "../../mockDataEnd";
import {useLocation} from "react-router-dom";

const NewsPage = () => {

    const news = [
        {
            id: 0 , titleNews: 'dasdsadas dasdas 1' , subtitleNews : 'fdsfsd fdsf sdfsdf sdfsdf sdfsdfs' , img: '1.1'
        },
        {
            id: 1 , titleNews: 'dasdsadas ds 1' , subtitleNews : 'fdsfsd fdsf sdfsdf sdfsdf sdfsdfs' , img: '1.2'
        },
        {
            id: 2 , titleNews: 'dasdsadas dads dasdas 1' , subtitleNews : 'fdsfsd fdsf  sdfsdf sdfsdfs' , img: '1.3'
        },
        {
            id: 3 , titleNews: 'dasdsadas dads dasdas 1 ' , subtitleNews : 'fdsfsd fdsf  sdfsdf sdfsdfs', img: '1.3'
        },

    ];


    const [count , setCount] = useState(1)
    const [PrevMonth ,setPrevMonth] = useState('')
    const [NextMonth ,setNextMonth] = useState('')
    const [sliderContent , setSliderContent] = useState(newsSoft)
    const [countSlider , SetcountSlider] = useState(0)
    const [swiperImg , setSwiperImg ] = useState({})
    const [slider , setSlider] = useState(news)
    const [animate , setAnimate] = useState('newsActive')
    const [title , setTitle] = useState('НОВИНИ')
    const loc = useLocation().pathname;

    function animateNews(){
        if (animate == 'newsActive'){
            setAnimate('newsSecond')
        }else {
            setAnimate('newsActive')
        }
    }

    const MonthActive = (count = 0 ) =>{
        setPrevMonth(sliderContent[count - 1])
    }


    const MonthNext = (count = 0 ) =>{
        if (count >= sliderContent.length){
            setNextMonth(sliderContent[0])
        }

        else {
            setNextMonth(sliderContent[count])
        }
    }

    const Slider = (count = 0) => {
        setSlider(sliderContent[count - 1].news)
    }

    const sliderImage = (countSlider) => {
        setSwiperImg(slider[countSlider])
    }

    function countSliderPlus(){
        SetcountSlider(countSlider + 1)
        if(countSlider >= slider.length -1){
            SetcountSlider(0)
        }
        animateNews()

    }

    function countSliderMinus(){
        SetcountSlider(countSlider -1 )
        if (countSlider <= 0){
            SetcountSlider(slider.length -1)
        }
        animateNews()
    }

    function setCountOnClick(i){
        SetcountSlider(i)
        animateNews()
    }


    function countPlus(){
        setCount(count + 1)
        if(count >= sliderContent.length){
            setCount(1)
        }
        SetcountSlider(0)
    }
    function countMinus(){
        setCount(count -1 )
        if (count <= 1){
            setCount(sliderContent.length)
        }
        SetcountSlider(0)
    }

    useEffect(() =>{
        const  header = document.querySelector('header')
        const  footerHome = document.querySelector('.footer')
        if (loc == '/news'){
            header.style.display = 'flex' ;
            footerHome.style.display = 'flex';
        }

        MonthActive(count)
        MonthNext(count)
        Slider(count)
        sliderImage(countSlider)
        if (localStorage.getItem('language') == 'en'){
            setSliderContent(newsSoftEng)
            setTitle('News')
        }else {
            setSliderContent(newsSoft)
            setTitle('Новини')
        }
    })








    const newsSlider =(
        <div className="news-page">
            <h1>Isetsoft</h1>
            <h2>{title}</h2>
            <div className="new-tabs-wrapper">
                <div className={slider.length == 1 ? "swiper-date new-one-child" : "swiper-date" }>
                    <div onClick={countMinus} className="name-month">{PrevMonth.title}</div>
                    <div className="pagination-line" style={{height: count * (100/(newsSoft.length + 2.2)) + '%' }}></div>
                    <div onClick={countPlus}  className="name-month name-month-before " > {NextMonth.title}</div>
                </div>
                <div className="news">
                    <div>
                        <div className="container-slides">
                            <h2 className={"news-years"}>
                                {PrevMonth.year}
                            </h2>
                            <div className="news-slider-container">
                                <div className="news-slider-wrapper" style={countSlider > 1?  {marginTop: '-' + (countSlider - 1) * 120 + 'px' } : {marginTop: '-' + (countSlider ) * 0 + 'px' } }>

                                    {slider.map((el , i) =>
                                        <div key={i} onClick={()=> setCountOnClick(i) } className={countSlider == i ? 'news-slide news-slide-active' : 'news-slide'}>
                                            <div className="news-slide-date">
                                                <p>{el.date}</p>
                                            </div>
                                            <div className="news-slide-block">
                                                <p className="title">{el.titleNews}</p>
                                                <p className="subtitle">{el.subtitleNews}</p>
                                                <a target={'_blank'} href={'tel:380681628957'} className="subtitle">{el.link}</a>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div className={slider.length == 1 ? "news-img new-one-child" : "news-img"} >
                                <h2 className={"news-years-mobile "}>
                                    2022
                                </h2>
                                    <div  className={"images " + animate }>
                                        <img src={'./img/news/'+ swiperImg.img} className={"main-news-img"} alt=""/>
                                            <img src="./img/icons/calendarDate.svg" className="images-date" alt=""/>
                                            <a>{swiperImg.dateSlider}</a>
                                            <img src="./img/icons/mapNews.svg" className="images-map" alt=""/>
                                            <a target="blank" href="https://goo.gl/maps/jm6tZMG3WE7t6P4f7" className="title-map">Lviv, Ukraine</a>
                                    </div>
                                <div style={slider.length == 1 ?{display:"none"} : {display:'flex'}} className="pagination-containers">
                                    <div className="pagination-container">
                                        <div className="pagination-bullet">
                                            <div className="pagination-wrapper" style={countSlider > 1 ? {marginLeft: '-' + (countSlider - 1) * (18) + 'px'} :{marginLeft: '-' + (countSlider) * (0) + 'px'}}>
                                                {slider.map((slide , i )=>
                                                    <div key={i} id={i} className={ countSlider == i ? ' bullet active-bullet': 'bullet'  }>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="swiper-pagination-academy">
                                            <p>0{countSlider + 1}</p>
                                            <span>
                                        \0{slider.length}
                                    </span>
                                        </div>
                                    </div>
                                    <div className="pagination-container">
                                        <div onClick={countSliderPlus} className="swiper-next"><img src="./img/icons/sliderNext.svg" alt=""/></div>
                                        <div onClick={countSliderMinus} className="swiper-prev"> <img src="./img/icons/sliderPrev.svg" className="slider-prev" alt=""/></div>
                                    </div>
                                </div>

                                <div className={slider.length == 1 ? 'pagination-none' : "pagination-mobile" }>
                                    <div className="pagination-container">
                                        <div onClick={countSliderMinus} className="swiper-prev"> <img src="./img/icons/sliderPrev.svg" className="slider-prev" alt=""/></div>
                                        <div className="pagination-bullet">
                                            <div className="pagination-wrapper" style={countSlider > 1 ? {marginLeft: '-' + (countSlider - 1) * (18) + 'px'} :{marginLeft: '-' + (countSlider) * (0) + 'px'}}>
                                                {slider.map((slide , i )=>
                                                    <div key={i} id={i} className={ countSlider == i ? ' bullet active-bullet': 'bullet'  }>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div onClick={countSliderPlus} className="swiper-next"><img src="./img/icons/sliderNext.svg" alt=""/></div>
                                    </div>
                                    <div className="pagination-container">
                                        <div className="swiper-pagination-academy">
                                            <p>0{countSlider + 1}</p>
                                            <span>
                                 \0{slider.length}
                                </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );


    return (
        <div className={'views-container'}>
                    <div className="wrapper page-animation">
                        {newsSlider}
                    </div>
        </div>

    );
};

export default NewsPage;
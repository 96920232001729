import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube , Autoplay } from "swiper";

import 'swiper/css';
import "swiper/css/effect-cube";

import {
    VuejsIcon,
    ReactIcon,
    JavaScriptIcon,
    HtmlIcon,
    CssIcon,
    AzureIcon,
    PhpIcon,
    RedisIcon,
    BootstrapIcon,
    KubernetesIcon,
    LinuxIcon,
    MysqlLogo,
    LaravelIcon,
    MongodbIcon, AngularIcon, AwsIcon, WPressIcon,

} from '../../icons';

const Cube = ({classCube ,deleySlider ,firstIcons , secondIcons ,firstClass , secondClass}) => {
    return (
        <Swiper
            effect={"cube"}
            grabCursor={true}
            loop={true}
            speed={2000}
            cubeEffect={{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
            }}
            autoplay={{
                delay: deleySlider,
                disableOnInteraction: false,
            }}
            modules={[EffectCube , Autoplay]}
            className={"mySwiper mySwiper" + classCube}
        >
            <SwiperSlide className={secondClass}>
                {firstIcons}
            </SwiperSlide>
            <SwiperSlide className={firstClass}>
                {secondIcons}
            </SwiperSlide>
        </Swiper>
    );
};

export default Cube;
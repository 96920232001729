import React, {useEffect, useState} from 'react';
import './ContactPage.css'
import { useHistory } from "react-router-dom";
import {TranslateContact} from '../../mockData'
import { TranslateContactEng} from "../../mockDataEnd";

const Modal = () => {
    let history = useHistory();
    const [translate ,setTranslate] = useState(TranslateContact)


    useEffect(()=>{
        if (localStorage.getItem('language') == 'en'){
            setTranslate(TranslateContactEng)
        }else {
            setTranslate(TranslateContact)
        }
    })

    function handleClick() {

        history.push("/contacts");
    }

    return (
          <div className={'overlay-modal displayBlock'}   onClick={handleClick} >
            <div className="model-body">
              <div className="modal-header" onClick={handleClick} ><img src="/img/icons/close.svg" alt=""/></div>
                <div className="modal-content">
                    <img src="/img/icons/modalIcon.svg" alt=""/>
                    <p className="title">{translate.popapTitle}</p>
                    <p className="subtitle">{translate.popapSubtitle}</p>
                </div>
            </div>
          </div>
    );
};

export default Modal;
import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import './HomePage.css'



const HomePage = () => {

    const loc = useLocation().pathname;

    useEffect(()=>{
        const  header = document.querySelector('header')
        const  foterHome = document.querySelector('.footer')
        if (loc == '/'){
            header.style.display = 'none' ;
            foterHome.style.display = 'none' ;
        }
    })

    return (
      <div className="wrapper wrapper-home">
        <NavLink to="/team-work" className={'home-page'}>
          <img className="company-logo" src="/img/logo/logo.svg" alt="some"/>
          <span className="company-title">Isetsoft Corporation</span>
          <span className="company-subtitle">Web Development Agency</span>
        </NavLink>
      </div>
    )
}

export default HomePage
import React , {useState, useEffect} from 'react';
import { useLocation , useHistory } from "react-router-dom";

import './ContactPage.css'
import axios from "axios";
import Select from "react-select";
import {InstagramIcon , WhatsappIcon , TelegramIcon , FacebookIcon} from "../../icons";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { TranslateContact , Options} from '../../mockData'
import {TranslateContactEng , OptionsEng} from "../../mockDataEnd";
import {error} from "three";

const ContactsPage = () => {
  const [name , setName] = useState('');
  const [contacts , setContact] = useState('');
  const [message , setDescription] = useState('');
  const [orderType , setTypeSite] = useState('');
  const [onFocused , setOnFocusContact] = useState('');
  const [onFocusedContact , setOnFocus] = useState('');
  const [onFocusedText , setOnFocusText] = useState('');
  const [translate ,setTranslate] = useState(TranslateContact)
  const [validate , setValidate] = useState(false);
  const loc = useLocation().pathname;
  let history = useHistory();

  useEffect(() => {
    const  header = document.querySelector('header')
    const  footerHome = document.querySelector('.footer')
    if (loc == '/contacts'){
      header.style.display = 'flex' ;
      footerHome.style.display = 'flex';
    }


    if (localStorage.getItem('language') == 'en'){
      setTranslate(TranslateContactEng)
      setOption(OptionsEng)
    }else {
      setTranslate(TranslateContact)
      setOption(Options)
    }
    const opt = localStorage.getItem('type');
    opt == null ? setTypeSite('Тип сайту') : setTypeSite(opt);
  });

  const [options , setOption ] = useState(Options)

  const onFocus = (placeholder) => {
    setOnFocus(placeholder);
  }
  const OnFocusText = (placeholder) => {
    setOnFocusText(placeholder)
  }

  const OnFocusContacts = (placeholder) => {
    setOnFocusContact(placeholder)
  }

  const getValue = () => {
    return orderType ? options.find(c => c.value == orderType  ) : ''
  }

  const onChange = (newValue) => {
    localStorage.setItem('type' , newValue.value)
    setTypeSite(newValue.value)
    validateForm();
  }

  function handleClick() {
    history.push("/contacts/success");
  }

  function validateForm(){
    if (name.length > 2 && contacts.length > 4  && localStorage.type != 'Тип сайту' && localStorage.type != null){
      setValidate(true);
    } else {
      setValidate(false);
    }
  }

  function onSubmit(e){
    e.preventDefault()


    const FormData = {
      name,
      contacts,
      message,
      orderType
    }


    if (name.length > 2 && contacts.length > 4 && localStorage.type != 'Тип сайту' && localStorage.type != null ){
      axios({
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: 'https://coin-magento.space/php-client/isetTelegramAPI.php',
        data: FormData
      }).then(response =>{
          setValidate(false)
          setName('')
          setContact('')
          setDescription('')
          setTypeSite('Тип сайту')
          handleClick();
      }).catch(error =>{
      })
    }
  }


  const content = (
      <div className={"contact-container"}>
        <div className="contact-link">
          <div className="contact-network">
            <a target="blank" href="https://www.facebook.com/Isetsoft-101178219433926">
              <img  src="./img/icons/facIcon.svg" alt=""/>
            </a>
            <a target="blank" href="https://t.me/iset_order_bot">
              <img src="./img/icons/telIcon.svg" alt=""/>
            </a>
          </div>
          <div className="contacts-info">
            <h1>Isetsoft</h1>
            <h2>{translate.title}</h2>
            <p> {translate.subtitle1} <br/>
              {translate.subtitle3} <br/> {translate.subtitle4}
            </p>
            <p className={'title'}>{translate.address}</p>
            <a target="blank" href="https://goo.gl/maps/jm6tZMG3WE7t6P4f7">{translate.nameAddress}</a>
            <p className={'title'}>{translate.mail}</p>
            <a target="blank" href="mailto:adminisetsoft@isetsoft.com">admin@isetsoft.com</a>
            <p className={'title'}>{translate.phone}</p>
            <a target="blank" href="tel:+380633246604">+38 (063) 324 66 04</a>
          </div>
        </div>
        <div className="contact-block">
          <div className="form">
            <h1>{translate.form}</h1>
            <h2>{translate.contact}</h2>
            <form  onSubmit={onSubmit}>
              <span>{onFocusedContact}</span>
              <input onBlur={() => setOnFocus('')} onFocus={event => onFocus(event.target.getAttribute('placeholder'))}  value={name} onChange={event => setName(event.target.value)} onInput={validateForm}  placeholder={translate.placeholder1}/>
              <span>{onFocused}</span>
              <input className="conatact-input" onBlur={() => setOnFocusContact('')} onFocus={event => OnFocusContacts(event.target.getAttribute('placeholder'))} value={contacts} onChange={event => setContact(event.target.value)} onInput={validateForm} placeholder={translate.placeholder2}/>
              <span>{onFocusedText}</span>
              <textarea value={message} onBlur={() => setOnFocusText('')} onFocus={event => OnFocusText(event.target.getAttribute('placeholder'))} placeholder={translate.placeholder3} onChange={event => setDescription(event.target.value)} onInput={validateForm}  />

              <Select
                  value={getValue()}
                  onChange={onChange}
                  maxMenuHeight={115}
                  options={options}
                  classNamePrefix={'custom-select'}
              />
              <button className={validate ? '' : 'validation-button'}>
                {translate.button}
              </button>
            </form>
          </div>
        </div>
      </div>

  );

  // const modalForm = (
  //
  //   <div className={'overlay-modal ' + modal }  onClick={ closeModal} >
  //     <div className="model-body">
  //       <div className="modal-header" ><img onClick={closeModal} src="/img/icons/close.svg" alt=""/></div>

  //     </div>
  //   </div>
  // );

  return (
      <div className={'views-container'}>
            <div className="contact-page-wpapper page-animation" >
              <div className="wrapper wrapper-mobile">
                {content}
                {/*{modalForm}*/}
                <div className="networks-block-phone">
                  <p className={'title'}>{translate.address}</p>
                  <a target="blank" href="https://goo.gl/maps/aECgJXT2BWXVkJrs8">{translate.nameAddress}</a>
                  <p className={'title'}>{translate.mail}</p>
                  <a target="blank" href="mailto:adminisetsoft@isetsoft.com">admin@isetsoft.com</a>
                  <p className={'title'}>{translate.phone}</p>
                  <a target="blank" href="tel:+380633246604">+38 (063) 324 66 04</a>
                </div>
              </div>
            </div>
      </div>
  );
}

export default ContactsPage


// <div className="form-networks">
//     <div className="">
//     <div className="networks-block">
//     <p>Зв'яжіться з нами</p>
// <a href="tel:+380633246604">+38 (063) 324 66 04</a>
// <div className="icons-network ">
//   <a target="blank"  href="https://t.me/iset_order_bot">
//     {TelegramIcon}
//   </a>
//   <a  target="blank" href="viber://pa?chatURI=isetsoft-agency">
//     {WhatsappIcon}
//   </a>
// </div>
// </div>
// <div className="networks-block">
//   <p>Наш офіс</p>
//   <a target="blank" href="https://goo.gl/maps/aECgJXT2BWXVkJrs8">м. Львів <br/>
//     вул. Шевченко 120г</a>
// </div>
// </div>
// <div className="networks-block">
//   <p>Ми в соцмережах</p>
//   <div className="icons-network">
//     <a target="blank" href="https://www.instagram.com/isetsoft/">
//       {InstagramIcon}
//     </a>
//     <a target="blank" href="https://www.facebook.com/Isetsoft-101178219433926">
//       {FacebookIcon}
//     </a>
//   </div>
// </div>
// </div>
// <div className="gogle-maps">
//   <iframe
//       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1402.7519137777888!2d23.998218448831636!3d49.84887567041449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add091d246cd9%3A0xed03fd4e1d64d880!2sKivsh!5e0!3m2!1sru!2sua!4v1662704576435!5m2!1sru!2sua"
//       width="350" height="525" style={{border:0}}  loading="lazy"
//       referrerPolicy="no-referrer-when-downgrade"></iframe>
// </div>
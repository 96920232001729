import React, {useState, useEffect} from "react";
import {NavLink} from "react-router-dom";
import './Header.css'
import Select from 'react-select'
import {HeaderTranslate} from '../../mockData'
import {HeaderTranslateEng} from '../../mockDataEnd'
const Header = () => {
  const [headerActive, setHeaderActive] = useState(false)
  const [headerTranslation , setHeaderTranslation] = useState({})
  const [lang , setLanguage] = useState('')
  const language = [
    { value: 'ua', label: 'UA' },
    { value: 'en', label: 'EN' },
  ]

  const getValue = () => {
    return lang ? language.find(c => c.value == lang  ) : ''
  }

  const onResize = () => {
    setHeaderActive(false);
  }

  const onLanguage = (value) => {
      localStorage.setItem('language' , value.value)
      setLanguage(value.value)
      window.location.reload()
  }

  const changeHeaderStatus = () => {
    const width = window.innerWidth;
    width > 1220 ? setHeaderActive(false) : setHeaderActive(!headerActive);
  }

  useEffect(() => {

    if (localStorage.getItem('language') == 'en'){
      setHeaderTranslation(HeaderTranslateEng)
    }else {
      setHeaderTranslation(HeaderTranslate)
    }

    const opt = localStorage.getItem('language')
    opt == null ? setLanguage('ua') : setLanguage(opt);

    window.addEventListener('resize', onResize)
  } , [headerTranslation])

  return (
    <header className="header">
      <div className="wrapper">
        <div className="header-content">
          <NavLink to="/" className="header-logo">
            <img src="/img/logo/logo.svg" alt="logo"/>
            <div className="company-name">
              ISETSOFT
            </div>
          </NavLink>
            <div className={headerActive ? "background-block-active header-active-div " : 'background-block-active'}>
            </div>
            <nav className={headerActive ? "header-active" : 'header-dizactive'}>
              <li>
                <NavLink activeClassName="active-tab" onClick={changeHeaderStatus} to="/news">{headerTranslation.news}</NavLink>
              </li>
              <li>
                <NavLink activeClassName="active-tab" onClick={changeHeaderStatus} to="/academy">{headerTranslation.academy}</NavLink>
              </li>
              <li>
                <NavLink activeClassName="active-tab" onClick={changeHeaderStatus} to="/projects">{headerTranslation.project}</NavLink>
              </li>
              <li>
                <NavLink activeClassName="active-tab" onClick={changeHeaderStatus} to="/skills">{headerTranslation.technologies}</NavLink>
              </li>
              <li>
                <NavLink activeClassName="active-tab" onClick={changeHeaderStatus} to="/team-work">{headerTranslation.team}</NavLink>
              </li>
              <li>
                <NavLink activeClassName="active-tab" onClick={changeHeaderStatus} to="/about">{headerTranslation.services}</NavLink>
              </li>
              <li>
                <NavLink activeClassName="active-tab" onClick={changeHeaderStatus} to="/contacts">{headerTranslation.contact}</NavLink>
              </li>
              <li className={'header-select'}>
                <Select
                    classNamePrefix={'header-select'}
                    value={getValue()}
                    onChange={onLanguage}
                    isSearchable={false}
                    options={language} />
              </li>
              <li className={'contacts-btn'}>
                <a target="blank" href={"https://t.me/iset_order_bot"}>{headerTranslation.buttonHeader}</a>
              </li>
            </nav>
            <div onClick={changeHeaderStatus} className={"header-burger"}>
              <span className={headerActive ? "burger-item  burger-item1 burger-item1-unactive" : 'burger-item  burger-item1'}></span>
              <span className={headerActive ? "burger-item  burger-item2 burger-item2-active" : 'burger-item  burger-item2'}></span>
              <span className={headerActive ? "burger-item  burger-item3 burger-item3-active" : 'burger-item  burger-item3'}></span>
              <span className={headerActive ? "burger-item  burger-item4 burger-item4-unactive" : 'burger-item  burger-item4'}></span>
            </div>
        </div>
      </div>
      <div onClick={changeHeaderStatus} className={headerActive ? "overlay overlay-active" : "overlay"}></div>
    </header>
  )
}

export default Header